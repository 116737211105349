import { CollectionInstanceIdentifiers, Answers } from '@breathelife/types';

import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '../answersResolver';

export function selectAnswersForNodeIds(
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  answers: Answers,
  relevantNodeIds: string[],
  collectionInstanceIdentifiers?: CollectionInstanceIdentifiers
): Answers {
  const answersInScope = {};
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);

  relevantNodeIds.forEach((nodeId) => {
    const answerPath = nodeIdToAnswerPathMap.get(nodeId);
    const answerValue = answersResolver.getAnswer(
      answers,
      nodeId,
      collectionInstanceIdentifiers,
      undefined,
      answerPath?.isCollection
    );

    if (typeof answerValue !== 'undefined') {
      answersResolver.setAnswer(answerValue, answersInScope, nodeId, collectionInstanceIdentifiers);
    }
  });

  return answersInScope;
}
