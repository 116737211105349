import _ from 'lodash';

import { CollectionInstanceIdentifiers, Answers } from '@breathelife/types';

import { NodeIdAnswersResolver } from './AnswersResolver';
import {
  AnswerPathCollectionTreeNode,
  AnswerPathTreeNode,
  isAnswerPathCollectionTreeNode,
  isAnswerPathLeafNode,
  makeNodeIdToAnswerPathMap,
} from './makeNodeIdToAnswerPathMap';

export class AnswersTransformer {
  private readonly originalAnswers: Answers;
  private readonly inputAnswersResolver: NodeIdAnswersResolver;

  constructor(originalAnswers: Answers, inputAnswersResolver: NodeIdAnswersResolver) {
    this.originalAnswers = _.cloneDeep(originalAnswers);
    this.inputAnswersResolver = inputAnswersResolver;
  }

  public transformTo(
    outputNodePaths: AnswerPathTreeNode[],
    inputCollectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    outputCollectionInstanceIdentifiers?: CollectionInstanceIdentifiers
  ): Answers {
    const outputAnswersResolver = new NodeIdAnswersResolver(makeNodeIdToAnswerPathMap(outputNodePaths));
    const answersOutput = {};

    this.traverseTreeNode(
      outputNodePaths,
      answersOutput,
      outputAnswersResolver,
      inputCollectionInstanceIdentifiers ?? {},
      outputCollectionInstanceIdentifiers ?? {}
    );
    return answersOutput;
  }

  private traverseTreeNode(
    treeNode: AnswerPathTreeNode[],
    answersOutput: Answers,
    outputAnswersResolver: NodeIdAnswersResolver,
    inputCollectionInstanceIdentifiers: CollectionInstanceIdentifiers,
    outputCollectionInstanceIdentifiers: CollectionInstanceIdentifiers
  ): void {
    for (const nextTreeNode of treeNode) {
      if (isAnswerPathLeafNode(nextTreeNode)) {
        this.transferAnswerAtNodeId(
          nextTreeNode.nodeId,
          answersOutput,
          outputAnswersResolver,
          inputCollectionInstanceIdentifiers,
          outputCollectionInstanceIdentifiers
        );
      } else if (isAnswerPathCollectionTreeNode(nextTreeNode)) {
        this.traverseCollectionTreeNode(
          nextTreeNode,
          answersOutput,
          outputAnswersResolver,
          inputCollectionInstanceIdentifiers,
          outputCollectionInstanceIdentifiers
        );
      } else {
        this.traverseTreeNode(
          nextTreeNode.children,
          answersOutput,
          outputAnswersResolver,
          inputCollectionInstanceIdentifiers,
          outputCollectionInstanceIdentifiers
        );
      }
    }
  }

  private traverseCollectionTreeNode(
    treeNode: AnswerPathCollectionTreeNode,
    answersOutput: Answers,
    outputAnswersResolver: NodeIdAnswersResolver,
    inputCollectionInstanceIdentifiers: CollectionInstanceIdentifiers,
    outputCollectionInstanceIdentifiers: CollectionInstanceIdentifiers
  ): void {
    const collection = this.inputAnswersResolver.getCollection(
      this.originalAnswers,
      treeNode.nodeId,
      inputCollectionInstanceIdentifiers
    );

    if (collection) {
      for (let i = 0; i < collection.length; i++) {
        this.traverseTreeNode(
          treeNode.children,
          answersOutput,
          outputAnswersResolver,
          { ...inputCollectionInstanceIdentifiers, [treeNode.nodeId]: i },
          { ...outputCollectionInstanceIdentifiers, [treeNode.nodeId]: i }
        );
      }
    }
  }

  private transferAnswerAtNodeId(
    nodeId: string,
    answersOutput: Answers,
    outputAnswersResolver: NodeIdAnswersResolver,
    inputCollectionInstanceIdentifiers: CollectionInstanceIdentifiers,
    outputCollectionInstanceIdentifiers: CollectionInstanceIdentifiers
  ): void {
    const answer = this.inputAnswersResolver.getAnswer(
      this.originalAnswers,
      nodeId,
      inputCollectionInstanceIdentifiers
    );
    if (AnswersTransformer.isAnswered(answer)) {
      outputAnswersResolver.setAnswer(answer, answersOutput, nodeId, outputCollectionInstanceIdentifiers);
    }
  }

  private static isAnswered(answer: unknown): boolean {
    return !_.isEmpty(answer) || _.isNumber(answer) || _.isBoolean(answer);
  }
}
