import _ from 'lodash';

import { InsuranceModule, Language } from '@breathelife/types';

import {
  RenderingField,
  RenderingQuestion,
  RenderingQuestionnaire,
  RenderingRepeatedQuestion,
  RenderingSection,
  RenderingSectionGroup,
  RenderingSubsection,
} from './renderingTransforms';
import {
  Field,
  FieldTypes,
  FieldValueTypes,
  Question,
  Questionnaire,
  Section,
  SectionGroup,
  Subsection,
} from './structure';

export function getAllQuestions(questionnaire: Questionnaire): Question[];
export function getAllQuestions(questionnaire: RenderingQuestionnaire): RenderingQuestion[];

export function getAllQuestions(questionnaire: any): any {
  const subsections = getAllSubsections(questionnaire);
  return _.flatMap(subsections, 'questions');
}

export function getAllRepeatableQuestions(questionnaire: Questionnaire): Question[] {
  return getAllQuestions(questionnaire).filter((question) => question.options?.repeatable);
}

export function getAllFields(questionnaire: Questionnaire): Field[] {
  const questions: Question[] = getAllQuestions(questionnaire);
  return _.flatMap(questions, 'fields');
}

export function getAllSections(questionnaire: Questionnaire): Section[];
export function getAllSections(questionnaire: RenderingQuestionnaire): RenderingSection[];

export function getAllSections(questionnaire: any): any {
  return _.flatMap(questionnaire, 'sections');
}

export function getAllSubsections(questionnaire: Questionnaire): Subsection[];
export function getAllSubsections(questionnaire: RenderingQuestionnaire): RenderingSubsection[];

export function getAllSubsections(questionnaire: any): any {
  const sections = getAllSections(questionnaire);
  return _.flatMap(sections, 'subsections');
}

export function getContainingSectionGroup(sectionGroups: SectionGroup[], section: Section): SectionGroup | undefined {
  return sectionGroups.find((sectionGroup) => sectionGroup.sections.includes(section));
}

export function getContainingSection(subsectionId: string, questionnaire: Questionnaire): Section | undefined {
  const allSections = getAllSections(questionnaire);
  return allSections.find((section) => section.subsections.some(({ id }) => id === subsectionId));
}

export function findSection(id: string, questionnaire: Questionnaire): Section | undefined;
export function findSection(id: string, questionnaire: RenderingQuestionnaire): RenderingSection | undefined;
export function findSection(id: string, questionnaire: any): any {
  return getAllSections(questionnaire).find((section) => section.id === id);
}

export function findSubsection(id: string, section: Section): Subsection | undefined {
  return section.subsections.find((subsection: Subsection): boolean => subsection.id === id);
}

export function findSubsectionInQuestionnaire(id: string, questionnaire: Questionnaire): Subsection | undefined {
  let subsection: Subsection | undefined;
  questionnaire.some((sectionGroup) =>
    sectionGroup.sections.some((section) => {
      subsection = findSubsection(id, section);
      return !!subsection;
    })
  );
  return subsection;
}

export function findQuestion(id: string, subsection: Subsection): Question | undefined {
  return subsection.questions.find((question: Question): boolean => question.id === id);
}

export function findField(id: string, question: Question): Field | undefined;
export function findField(id: string, questionnaire: Questionnaire): Field | undefined;

export function findField(id: string, searchIn: Question | Questionnaire): Field | undefined {
  if (Array.isArray(searchIn)) {
    const allFields: Field[] = getAllFields(searchIn);
    return allFields.find((field: Field) => field.id === id);
  }
  return searchIn.fields.find((field: Field): boolean => field.id === id);
}

export function sectionGroupHasSections(sectionGroup: RenderingSectionGroup): boolean {
  return sectionGroup.sections.length > 0;
}

export function getRepeatedQuestionIndex(question: RenderingRepeatedQuestion): number {
  return question.metadata.repetitionIndex;
}

// Returns the initial 'blank' field value depending on the field value type
// This is needed because Material-UI components have display issues when given `undefined` as a value
// https://app.asana.com/0/0/1112224517643558/f
export function getInitialFieldValue(field: RenderingField | Field): any {
  const answerType: FieldValueTypes = getFieldValueType(field);
  switch (answerType) {
    case FieldValueTypes.string:
      return '';
    case FieldValueTypes.boolean:
      return false;
    case FieldValueTypes.array:
      return [];
  }
}

// Returns the type of the value expected by the UI for a given field
// Note that this only represents the type used in the UI and is not indicative of the type of our persisted answers
// For example, a number field uses a text input (meaning the UI expects a string) but will be persisted as a number
export function getFieldValueType(field: RenderingField | Field): FieldValueTypes {
  switch (field.type) {
    case FieldTypes.agree:
    case FieldTypes.checkbox:
      return FieldValueTypes.boolean;
    case FieldTypes.checkboxGroup:
      return FieldValueTypes.array;
    default:
      return FieldValueTypes.string;
  }
}

export function getFieldTitlesByNodeId(questionnaire: Questionnaire, lang: Language): Map<string, string> {
  const fieldTitlesByNodeId = new Map<string, string>();
  questionnaire.forEach((sectionGroup) => {
    sectionGroup.sections.forEach((section) => {
      section.subsections.forEach((subsection) => {
        subsection.questions.forEach((question) => {
          question.fields.forEach((field) => {
            if (fieldTitlesByNodeId.has(field.nodeId)) {
              return; // Already have a title for this nodeId.
            }
            let title = '';
            if (field.title?.[lang]) {
              title = field.title[lang];
            } else if (field.text?.[lang]) {
              title = field.text[lang];
            } else if (question.title?.[lang]) {
              title = question.title?.[lang];
            } else if (question.text?.[lang]) {
              title = question.text?.[lang];
            } else {
              // use the nodeId as the fallback as it will be easier to debug if we ever end up in that situation where
              // field and question do not have title/subtitle information.
              title = field.nodeId;
            }
            fieldTitlesByNodeId.set(field.nodeId, title);
          });
        });
      });
    });
  });
  return fieldTitlesByNodeId;
}

export function getSubsectionInsuranceModules(subsectionId: string, questionnaire: Questionnaire): InsuranceModule[] {
  const parentSection = getContainingSection(subsectionId, questionnaire);
  return parentSection?.insuranceModuleFilter ?? [];
}
