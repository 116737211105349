import _ from 'lodash';

import { Answers, IAnswerResolver } from '@breathelife/types';

import { Questionnaire } from '../structure';
import { splitSensitiveAnswers } from './sensitiveAnswers';

export type DataEncryptionFunction = (data: Buffer, kmsKeyPath: string, passThroughMode?: boolean) => Promise<Buffer>;

export type EncryptionConfig = {
  kmsKeyPath: string;
  passThroughMode?: boolean;
};

export class AnswersEncryption {
  private readonly encrypt: DataEncryptionFunction;
  private readonly decrypt: DataEncryptionFunction;
  private readonly config: EncryptionConfig;
  private readonly questionnaire?: Questionnaire;
  private readonly answerResolver?: IAnswerResolver;

  constructor(
    encrypt: DataEncryptionFunction,
    decrypt: DataEncryptionFunction,
    config: EncryptionConfig,
    questionnaire?: Questionnaire,
    answerResolver?: IAnswerResolver
  ) {
    this.encrypt = encrypt;
    this.decrypt = decrypt;
    this.config = config;
    if (questionnaire) this.questionnaire = questionnaire;
    if (answerResolver) this.answerResolver = answerResolver;
  }

  /** Merges unencrypted/encrypted answers into a single blob of decrypted answers */
  public async mergeEncryptedAnswers(unencryptedAnswers: Answers, encryptedAnswers: Buffer | null): Promise<Answers> {
    if (!encryptedAnswers) return unencryptedAnswers;
    const decryptedAnswers: Answers = (await this.decryptData(encryptedAnswers)) as Answers;
    return _.merge(unencryptedAnswers, decryptedAnswers);
  }

  /** Splits a blob of merged unencrypted/decrypted answers into separate unencrypted/encrypted answers */
  public async encryptAnswers(answers: Answers): Promise<{ unencryptedAnswers: Answers; encryptedAnswers: Buffer }> {
    if (!this.questionnaire) {
      throw new Error('questionnaire must be provided to encrypt the answers');
    }
    if (!this.answerResolver) {
      throw new Error('answerResolver must be provided to encrypt the answers');
    }
    const { sensitiveAnswers, nonSensitiveAnswers } = splitSensitiveAnswers(
      this.questionnaire,
      this.answerResolver,
      answers
    );

    return {
      encryptedAnswers: await this.encryptData(sensitiveAnswers),
      unencryptedAnswers: nonSensitiveAnswers,
    };
  }

  // Any change altering the behavior of this function should also be applied to the crypto implementation for lacapitale migrations.
  public async encryptData(decryptedData: any): Promise<Buffer> {
    const stringifiedData = JSON.stringify(decryptedData);
    return this.encrypt(Buffer.from(stringifiedData), this.config.kmsKeyPath, this.config.passThroughMode);
  }

  // Any changes altering the behavior of this function should also be applied to the crypto implementation for lacapitale migrations.
  public async decryptData(encryptedData: Buffer): Promise<unknown> {
    const decryptedBuffer: Buffer = await this.decrypt(
      encryptedData,
      this.config.kmsKeyPath,
      this.config.passThroughMode
    );
    const stringifiedData = decryptedBuffer.toString();
    return JSON.parse(stringifiedData);
  }
}
