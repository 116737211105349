import _ from 'lodash';

import { Timezone } from '@breathelife/types';

import { hasBeenAnswered } from '../answers';
import { EvaluatedVisibilityQuestionnaire } from '../nodeEvaluation/visibleIf/visibility';
import { Field, FieldTypes, isSectionGroupRepeatable, SectionGroup } from '../structure';
import { FieldValidationSchemas, makeFieldValidationSchemas, Validations } from '../validations';
import { TransitionNode, TransitionNodeWithMetadata, TransitionNodeWithVisibility } from './Questionnaire';
import { FieldWithValue, transformFieldsInSection } from './QuestionnaireTransforms';
import { RenderingField, RenderingRepeatedSectionGroup } from './RenderingQuestionnaire';
import { ActiveSectionId } from './navigation';

type UnevaluatedValidationQuestionnaire = EvaluatedVisibilityQuestionnaire & UnevaluatedValidationSectionGroup[];

type UnevaluatedValidationSectionGroup = TransitionNodeWithMetadata &
  TransitionNodeWithVisibility & {
    sections: (TransitionNodeWithVisibility & {
      subsections: (TransitionNodeWithVisibility & {
        questions: (TransitionNodeWithMetadata &
          TransitionNodeWithVisibility & {
            fields: UnevaluatedValidationField[];
          })[];
      })[];
    })[];
  };

type UnevaluatedValidationField = TransitionNodeWithMetadata &
  TransitionNodeWithVisibility &
  Pick<RenderingField, 'disabled' | 'optional'> &
  Pick<Field, 'validation'> &
  FieldWithValue;

type EvaluatedValidationField = TransitionNode & Pick<RenderingField, 'valid' | 'validationError'>;

export function setValidationFields(
  questionnaire: UnevaluatedValidationQuestionnaire,
  shouldValidateAllAnswers: boolean,
  text: (value: string, params: any) => string,
  logValidationErrors: boolean,
  timezone: Timezone,
  activeSectionId?: ActiveSectionId
): void {
  const fieldSchemas = makeFieldValidationSchemas(text, timezone);
  const validateActiveSectionOnly: boolean = typeof activeSectionId !== 'undefined';

  questionnaire.forEach((sectionGroup: UnevaluatedValidationSectionGroup) => {
    let sectionGroupId: string;
    let sectionGroupRepetitionIndex: number | undefined;

    const isRepeatableSectionGroup = isSectionGroupRepeatable(sectionGroup as unknown as SectionGroup);
    if (isRepeatableSectionGroup) {
      const metadata = (sectionGroup as unknown as RenderingRepeatedSectionGroup).metadata;
      sectionGroupId = metadata.parentId;
      sectionGroupRepetitionIndex = metadata.repetitionIndex;
    } else {
      sectionGroupId = sectionGroup.id;
    }

    sectionGroup.sections.forEach((section: TransitionNode) => {
      const sectionId: ActiveSectionId = {
        sectionGroupId,
        sectionGroupRepetitionIndex,
        sectionId: section.id,
      };
      const isActiveSection: boolean = _.isEqual(sectionId, activeSectionId);
      const stopOnError: boolean = validateActiveSectionOnly && !isActiveSection;
      setSectionValidationFields(section, fieldSchemas, stopOnError, shouldValidateAllAnswers, logValidationErrors);
    });
  });
}

function setSectionValidationFields(
  section: TransitionNode,
  fieldSchemas: FieldValidationSchemas,
  stopOnError: boolean,
  shouldValidateAllAnswers: boolean,
  logValidationErrors: boolean
): void {
  transformFieldsInSection(section, (field: UnevaluatedValidationField, stopVisit: () => void): void => {
    if (!field.visible) return;
    const updatedField = setFieldValidation(field, fieldSchemas, shouldValidateAllAnswers, logValidationErrors);
    if (stopOnError && !updatedField.valid) {
      stopVisit();
    }
  });
}

function setFieldValidation(
  field: UnevaluatedValidationField,
  fieldSchemas: FieldValidationSchemas,
  shouldValidateAllAnswers: boolean,
  logValidationErrors: boolean
): EvaluatedValidationField {
  // Fields explicitly marked optional:false are always required
  const isRequired =
    field.optional === false || (!field.optional && !field.disabled && field.type !== FieldTypes.information);

  const fieldValidationType: Validations = field.validation.type;
  const fieldSchema = isRequired
    ? fieldSchemas.required[fieldValidationType]
    : fieldSchemas.optional[fieldValidationType];

  const fieldWithEvaluatedValidation = field as EvaluatedValidationField;
  try {
    fieldSchema.validateSync(field.value);
    fieldWithEvaluatedValidation.valid = true;
  } catch (error) {
    fieldWithEvaluatedValidation.valid = false;
    if (shouldValidateAllAnswers || hasBeenAnswered(field.value)) {
      fieldWithEvaluatedValidation.validationError = { message: error.message };
    }
    if (logValidationErrors) {
      console.info(`Answer for field id ${field.id} is invalid.`);
    }
  }
  return fieldWithEvaluatedValidation;
}
