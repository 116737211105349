import _ from 'lodash';

import { RenderingSection, RenderingSectionGroup } from './RenderingQuestionnaire';

export type ActiveSectionId = {
  sectionGroupId: string;
  sectionGroupRepetitionIndex?: number;
  sectionId: string;
};

export function getNextSection(
  sectionGroup?: RenderingSectionGroup,
  section?: RenderingSection
): RenderingSection | undefined {
  if (!sectionGroup || !section) {
    return undefined;
  }

  const sectionIndex = _.indexOf(sectionGroup.sections, section);
  return sectionGroup.sections[sectionIndex + 1];
}

export function getPreviousSectionFromAllGroups(
  sectionGroups: RenderingSectionGroup[],
  sectionGroup: RenderingSectionGroup,
  section: RenderingSection
): RenderingSection | undefined {
  const previousSectionInGroup = getPreviousSection(sectionGroup, section);
  if (previousSectionInGroup) {
    return previousSectionInGroup;
  }

  // No previous section found in current group, check previous group.
  const sectionGroupIndex = sectionGroups.indexOf(sectionGroup);
  const previousSectionGroup = sectionGroups[sectionGroupIndex - 1];
  if (previousSectionGroup?.sections.length) {
    // A previous section group exists, take the last section in that group.
    return previousSectionGroup.sections[previousSectionGroup.sections.length - 1];
  }

  return undefined;
}

export function getNextSectionFromAllGroups(
  sectionGroups: RenderingSectionGroup[],
  sectionGroup: RenderingSectionGroup,
  section: RenderingSection
): RenderingSection | undefined {
  const nextSectionInGroup = getNextSection(sectionGroup, section);
  if (nextSectionInGroup) {
    return nextSectionInGroup;
  }

  // No next section found in current group, check next group.
  const sectionGroupIndex = sectionGroups.indexOf(sectionGroup);
  const nextSectionGroup = sectionGroups[sectionGroupIndex + 1];
  if (nextSectionGroup?.sections.length) {
    // A next section group exists, take the first section in that group.
    return nextSectionGroup.sections[0];
  }

  return undefined;
}

export function getPreviousSection(
  sectionGroup?: RenderingSectionGroup,
  section?: RenderingSection
): RenderingSection | undefined {
  if (!sectionGroup || !section) {
    return undefined;
  }

  const sectionIndex = _.indexOf(sectionGroup.sections, section);
  return sectionGroup.sections[sectionIndex - 1];
}
