import { Localizable } from '@breathelife/types';

export function formatHeightToFeetAndCm(heightInches: number): Localizable {
  const heightTextInCm: string = `${Math.round(heightInches * 2.54)} cm`;
  const heightTextInFeet: string = `${Math.floor(heightInches / 12)}'${Math.floor(heightInches % 12)}"`;
  const formatted = `${heightTextInFeet} / ${heightTextInCm}`;
  return {
    fr: formatted,
    en: formatted,
  };
}

export function formatWeightToLbsAndKg(weightLbs: number): Localizable {
  const fullPounds = Math.floor(weightLbs);
  const roundedOunces = Math.round((weightLbs % 1) * 10 * 1.6);
  const weightKg = weightLbs * 0.453592;

  const formatted = `${fullPounds} lbs${roundedOunces !== 0 ? ' ' + roundedOunces + ' oz' : ''} / ${weightKg.toFixed(
    2
  )} kg`;
  return {
    fr: formatted,
    en: formatted,
  };
}
