import { PdfDocumentType } from '@breathelife/types';

import { sectionGroupHasSections } from './questionnaire';
import { RenderingQuestionnaire, RenderingSection } from './renderingTransforms';

export function filterQuestionnaireSectionsWithDocumentType(
  questionnaire: RenderingQuestionnaire,
  documentType: PdfDocumentType
): RenderingQuestionnaire {
  return questionnaire
    .map((sectionGroup) => ({
      ...sectionGroup,
      sections: sectionGroup.sections.filter((section) => isSectionSupported(section, documentType)),
    }))
    .filter(sectionGroupHasSections);
}

function isSectionSupported(section: RenderingSection, documentType: PdfDocumentType): boolean {
  return section.documentTypes.includes(documentType);
}
