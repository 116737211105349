import _ from 'lodash';

import { Answers, IAnswerResolver } from '@breathelife/types';

import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '../answersResolver';
import { getAllSubsections } from '../questionnaire';
import { isQuestionRepeatable, Questionnaire } from '../structure';
import { selectAnswersForNodeIds } from '../utils/selectAnswersForNodeIds';

export function hasBeenAnswered(answer: unknown): boolean {
  return !_.isEmpty(answer) || _.isNumber(answer) || answer === true;
}

// Similar to https://github.com/hughsk/flat, but will not flatten arrays when they're leaves in the answers structure
export function flattenAnswers(
  answers: Answers | Answers[],
  delimiter: string,
  currentPath?: string,
  flatAnswers: { [fieldId: string]: any } = {}
): { [fieldId: string]: any } {
  if (areAnswersForRepeatedQuestions(answers)) {
    answers.forEach((obj: Answers, index: number) => {
      const newPath: string = currentPath ? `${currentPath}${delimiter}${index}` : `${index}`;
      flattenAnswers(obj, delimiter, newPath, flatAnswers);
    });
    return flatAnswers;
  }

  if (Array.isArray(answers)) {
    // leaf is an array, so we do not flatten it
    // answers are related to a multi-choice type, e.g. checkboxGroup
    _.set(flatAnswers, [currentPath ?? ''], answers);
    return flatAnswers;
  }

  Object.entries(answers).forEach(([key, value]) => {
    const newPath: string = currentPath ? `${currentPath}${delimiter}${key}` : key;
    if (typeof value === 'object' && value !== null) {
      flattenAnswers(value, delimiter, newPath, flatAnswers);
    } else {
      _.set(flatAnswers, [newPath], value);
    }
  });

  return flatAnswers;
}

export function areAnswersForRepeatedQuestions(answers: Answers | Answers[]): boolean {
  return Array.isArray(answers) && answers.every((answer: any) => answer && typeof answer === 'object');
}

export function getAllAnswersWithinSubsectionScope(
  questionnaire: Questionnaire,
  subsectionId: string,
  answers: Answers,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap
): Answers {
  const nodeIds = getStepNodeIds(subsectionId, questionnaire);

  const answersForNodeIds = selectAnswersForNodeIds(nodeIdToAnswerPathMap, answers, nodeIds);
  return answersForNodeIds;
}

export function getMergedStepAnswers(
  subsectionId: string,
  stepAnswers: Answers,
  existingAnswers: Answers,
  answersResolver: IAnswerResolver,
  questionnaire: Questionnaire
): Answers {
  const subsection = getAllSubsections(questionnaire).find((subsection) => subsection.id === subsectionId);
  if (!subsection) throw new Error(`Subsection with id ${subsectionId} does not exist`);

  const stepNodeIds = getStepNodeIds(subsectionId, questionnaire);

  const updatedAnswers = _.cloneDeep(existingAnswers ?? {});
  stepNodeIds.forEach((stepNodeId) => {
    const stepAnswer = answersResolver.isCollection(stepNodeId)
      ? answersResolver.getCollection(stepAnswers, stepNodeId, {})
      : answersResolver.getAnswer(stepAnswers, stepNodeId, {});

    answersResolver.setAnswer(stepAnswer, updatedAnswers, stepNodeId, {});
  });

  return updatedAnswers;
}

function getStepNodeIds(subsectionId: string, questionnaire: Questionnaire): string[] {
  const subsection = getAllSubsections(questionnaire).find((subsection) => subsection.id === subsectionId);
  if (!subsection) throw new Error(`Subsection with id ${subsectionId} does not exist`);

  const stepNodeIds: string[] = [];

  subsection.questions.forEach((question) => {
    if (isQuestionRepeatable(question)) {
      stepNodeIds.push(question.nodeId);
    } else {
      question.fields.forEach((field) => stepNodeIds.push(field.nodeId));
    }
  });

  return stepNodeIds;
}

export function areAnyFieldsAnsweredWithinSubsectionScope(
  questionnaire: Questionnaire,
  subsectionId: string,
  answers: Answers,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap
): boolean {
  const subsection = getAllSubsections(questionnaire).find((section) => section.id === subsectionId);
  if (!subsection) throw new Error(`Subsection with id ${subsectionId} does not exist`);
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);

  return subsection.questions.some((question) => {
    return question.fields.some((field) => {
      if (isQuestionRepeatable(question)) {
        const repetitions = answersResolver.getRepetitionCount(answers, question.nodeId, {});

        return (
          typeof repetitions !== 'undefined' &&
          _.range(repetitions).some((index) => {
            const answer = answersResolver.getAnswer(answers, field.nodeId, { [question.nodeId]: index });
            return hasBeenAnswered(answer);
          })
        );
      }

      const answer = answersResolver.getAnswer(answers, field.nodeId);
      return hasBeenAnswered(answer);
    });
  });
}
