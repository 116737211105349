import _ from 'lodash';

import { Language, Localizable } from '@breathelife/types';

import { ValidityRule } from './nodeEvaluation';
import {
  RenderingAgreeField,
  RenderingBaseField,
  RenderingBaseQuestion,
  RenderingBaseSectionGroup,
  RenderingButtonField,
  RenderingFieldOption,
  RenderingInfoSupplement,
  RenderingRepeatedQuestion,
  RenderingSection,
  RenderingSubsection,
  TransitionNode,
} from './renderingTransforms';
import {
  Field,
  isAgreeField,
  isButtonField,
  isOptionField,
  isPlaceholderField,
  isQuestionRepeatable,
  Question,
  Questionnaire,
  Section,
  SectionGroup,
  Subsection,
} from './structure';

export type TextGetter = (key: string, params?: { [key: string]: any }) => string;

export type LocalizedQuestionnaire = (TransitionNode & LocalizedSectionGroup)[];

export type LocalizedSectionGroup = TransitionNode &
  Pick<RenderingBaseSectionGroup, 'title'> & {
    sections: LocalizedSection[];
  };

export type LocalizedSection = TransitionNode &
  Pick<RenderingSection, 'title' | 'text'> & {
    subsections: LocalizedSubsection[];
  };

export type LocalizedSubsection = TransitionNode &
  Pick<RenderingSubsection, 'title' | 'text'> & {
    questions: LocalizedQuestion[];
  };

export type LocalizedQuestion = LocalizedBaseQuestion | LocalizedRepeatedQuestion;

type LocalizedBaseQuestion = TransitionNode &
  Pick<RenderingBaseQuestion, 'title' | 'text'> & {
    fields: LocalizedField[];
  };

export type LocalizedRepeatedQuestion = LocalizedBaseQuestion &
  Pick<RenderingRepeatedQuestion, 'title' | 'text' | 'addQuestionButtonText' | 'removeQuestionButtonText'>;

export type LocalizedField =
  | LocalizedBaseField
  | LocalizedTextField
  | LocalizedAgreeField
  | LocalizedAgreeField
  | LocalizedButtonField
  | LocalizedInfoSupplement
  | LocalizedOptionField;

type LocalizedPlaceholderField = LocalizedBaseField & {
  placeholder?: string;
};

type LocalizedBaseField = TransitionNode &
  Pick<RenderingBaseField, 'title' | 'text' | 'label' | 'info' | 'validationError'>;

export type LocalizedTextField = LocalizedPlaceholderField;

export type LocalizedAgreeField = LocalizedBaseField &
  Pick<RenderingAgreeField, 'title' | 'label' | 'confirmedLabel' | 'modalHeader' | 'modalText'>;

export type LocalizedButtonField = LocalizedBaseField & Pick<RenderingButtonField, 'buttonText'>;

export type LocalizedOptionField = LocalizedPlaceholderField & { options: LocalizedOption[] };

type LocalizedOption = TransitionNode & Pick<RenderingFieldOption, 'title' | 'text' | 'info'>;

export type LocalizedInfoSupplement = TransitionNode & Pick<RenderingInfoSupplement, 'title' | 'text'>;

export function localizeQuestionnaire(questionnaire: Questionnaire, language: Language): LocalizedQuestionnaire {
  _.forEach(questionnaire, (sectionGroup) => {
    localizeSectionGroup(sectionGroup, language);
  });

  return questionnaire as unknown as LocalizedQuestionnaire;
}

export function localizeSectionGroup(sectionGroup: SectionGroup, language: Language): LocalizedSectionGroup {
  const localizedSectionGroup = sectionGroup as unknown as LocalizedSectionGroup;

  localizedSectionGroup.title = localize(sectionGroup.title, language) ?? '';
  localizedSectionGroup.text = localize(sectionGroup.text, language);

  _.forEach(sectionGroup.sections, (section) => localizeSection(section, language));
  return localizedSectionGroup;
}

export function localizeSection(section: Section, language: Language): LocalizedSection {
  const localizedSection = section as unknown as LocalizedSection;

  localizedSection.title = localize(section.title, language) ?? '';
  localizedSection.text = localize(section.text, language);

  _.forEach(section.subsections, (subsection) => localizeSubsection(subsection, language));

  return localizedSection;
}

export function localizeSubsection(subsection: Subsection, language: Language): LocalizedSubsection {
  const localizedSubsection = subsection as unknown as LocalizedSubsection;

  localizedSubsection.title = localize(subsection.title, language) ?? '';
  localizedSubsection.text = localize(subsection.text, language);
  localizedSubsection.nextStepButtonText = localize(subsection.nextStepButtonText, language);

  _.forEach(subsection.questions, (question) => localizeQuestion(question, language));

  return localizedSubsection;
}

export function localizeQuestion(question: Question, language: Language): LocalizedQuestion {
  const localizedQuestion = question as unknown as LocalizedQuestion;

  localizedQuestion.title = localize(question.title, language);
  localizedQuestion.text = localize(question.text, language);

  if (isQuestionRepeatable(question)) {
    const localizedRepeatedQuestion = localizedQuestion as LocalizedRepeatedQuestion;
    localizedRepeatedQuestion.addQuestionButtonText = localize(question.addQuestionButtonText, language) ?? '';
    localizedRepeatedQuestion.removeQuestionButtonText = localize(question.removeQuestionButtonText, language) ?? '';
  }
  _.forEach(question.fields, (field) => localizeField(field, language));

  return localizedQuestion;
}

export function localizeField(field: Field, language: Language): LocalizedField {
  const localizedField: LocalizedField = field as unknown as LocalizedField;

  localizedField.title = localize(field.title, language);
  localizedField.text = localize(field.text, language);
  localizedField.label = localize(field.label, language);

  if (isPlaceholderField(field)) {
    localizedField.placeholder = localize(field.placeholder, language);
  }

  if (field.info) {
    const localizedInfoSupplement = localizedField.info as LocalizedInfoSupplement;
    localizedInfoSupplement.text = localize(field.info.text, language) ?? '';
    localizedInfoSupplement.title = localize(field.info.title, language);
  }

  if (field.validIf?.length) {
    field.validIf.forEach((rule) => {
      const localizedRule = rule as unknown as ValidityRule<string>;
      localizedRule.message = localize(rule.message, language) as string;
    });
  }

  if (isOptionField(field)) {
    const localizedOptionField = localizedField as LocalizedOptionField;

    localizedOptionField.options = _.map(field.options, (option) => {
      const optionTitle = localize(option.title, language);
      const optionText = localize(option.text, language) ?? '';
      const optionInfo = option.info
        ? {
            ...option.info,
            title: localize(option.info.title, language),
            text: localize(option.info.text, language) ?? '',
          }
        : undefined;

      return {
        ...option,
        text: optionText,
        title: optionTitle,
        info: optionInfo,
      } as LocalizedOption;
    });
  } else if (isAgreeField(field)) {
    const localizedAgreeField = localizedField as LocalizedAgreeField;

    localizedAgreeField.confirmedLabel = localize(field.confirmedLabel, language) ?? '';
    localizedAgreeField.modalHeader = localize(field.modalHeader, language) ?? '';
    localizedAgreeField.modalText = localize(field.modalText, language) ?? '';
  } else if (isButtonField(field)) {
    const localizedButtonField = localizedField as LocalizedButtonField;

    localizedButtonField.buttonText = localize(field.buttonText, language) ?? '';
  }

  return localizedField;
}

export function localize(input: Localizable | undefined, language: Language): string | undefined {
  if (!input) return undefined;
  return input[language];
}
