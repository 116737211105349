import _ from 'lodash';

import { evaluateConditions } from '@breathelife/condition-engine';
import {
  EngineEffects,
  InsuranceModule,
  InsuranceScopes,
  Language,
  PlatformType,
  RenderingType,
  Answers,
  QuestionnaireScreenConfig,
  Timezone,
  ApplicationMode,
} from '@breathelife/types';

import { RepeatedIndices } from '../answers';
import { AnswerPath, NodeIdAnswersResolver } from '../answersResolver';
import { computedQuestionnaireAnswers } from '../computedAnswers';
import { defaultQuestionnaireAnswers } from '../defaultAnswers';
import { TextGetter } from '../locale';
import { filterNodesByScope } from '../nodeEvaluation/filterNodesByScope';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { calculateProgress } from '../progress';
import { getAllSubsections } from '../questionnaire';
import { filterNodesByApplicationMode } from '../questionnaire/filterNodesByApplicationMode';
import { filterNodesByPlatformType } from '../questionnaire/filterNodesByPlatformType';
import { filterQuestionnaireSectionsByInsuranceModule } from '../questionnaire/filterQuestionnaireSectionsByInsuranceModule';
import { extendQuestionnaireWithScreen } from '../questionnaireHelpers/questionnaireScreenExtenderVisitor';
import {
  ActiveSectionId,
  DEFAULT_CONFIG,
  RenderingQuestionnaire,
  RenderingQuestionnaireGenerator,
  RenderingQuestionnaireGeneratorConfig,
} from '../renderingTransforms';
import { BlockingSubsection, isBlockingSubsection, Questionnaire, Subsection } from '../structure';
import { updateAnswer } from '../updateAnswer';
import { areAllFieldsValidAndComplete } from '../validations';

type QuestionnaireContext = {
  scopes?: InsuranceScopes[];
  platformTypes?: PlatformType[];
  insuranceModules?: InsuranceModule[];
  applicationModes?: ApplicationMode[];
};

export type QuestionnaireEngineConfig = RenderingQuestionnaireGeneratorConfig & {
  screenConfig?: QuestionnaireScreenConfig;
};

type RenderingOpts = {
  renderingType: RenderingType;
  shouldValidateAllAnswers: boolean;
  loadingFields?: boolean;
  allFieldsCompleted?: boolean;
  activeSectionId?: ActiveSectionId;
};

export class QuestionnaireEngine {
  private readonly questionnaire: Questionnaire;
  private readonly answersResolver: NodeIdAnswersResolver;
  private readonly visibilityDependencyMap: VisibilityDependencyMap;
  private readonly config: QuestionnaireEngineConfig;
  private readonly timezone: Timezone;

  constructor(
    questionnaire: Questionnaire,
    nodeIdToAnswerPathMap: Map<string, AnswerPath>,
    context: QuestionnaireContext = {},
    config: QuestionnaireEngineConfig = DEFAULT_CONFIG,
    timezone: Timezone
  ) {
    this.questionnaire = questionnaire;
    this.answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
    this.config = config;

    const { scopes, platformTypes, insuranceModules, applicationModes } = context;
    if (insuranceModules) {
      this.questionnaire = filterQuestionnaireSectionsByInsuranceModule(this.questionnaire, insuranceModules);
    }
    if (scopes) {
      this.questionnaire = filterNodesByScope(this.questionnaire, scopes);
    }
    if (platformTypes) {
      this.questionnaire = filterNodesByPlatformType(this.questionnaire, platformTypes);
    }
    if (applicationModes) {
      this.questionnaire = filterNodesByApplicationMode(this.questionnaire, applicationModes);
    }
    if (config.screenConfig) {
      this.questionnaire = extendQuestionnaireWithScreen(this.questionnaire, config.screenConfig);
    }

    this.timezone = timezone;

    this.visibilityDependencyMap = new VisibilityDependencyMap(this.questionnaire);
  }

  /**
   * Returns a new `Answers` object with default values and processed computed values as specified in the questionnaire.
   * If an `Answers` object is passed in with existing answer values, these values will not be overwritten; only missing values will be replaced with the default values. A new `Answers` object is returned.
   *
   * @example
   * const answers = { name: 'Joe' };
   * const answersWithDefaultValues = engine.getAnswersWithDefaultValues(answers); => { name: 'Joe', province: 'QC' }
   */
  public getAnswersWithDefaultValues(answers: Answers = {}): Answers {
    let updatedAnswers = _.cloneDeep(answers);

    updatedAnswers = defaultQuestionnaireAnswers(
      this.questionnaire,
      this.answersResolver,
      this.visibilityDependencyMap,
      updatedAnswers,
      this.timezone
    );
    updatedAnswers = computedQuestionnaireAnswers(
      this.questionnaire,
      this.answersResolver,
      this.visibilityDependencyMap,
      updatedAnswers,
      this.timezone
    );

    return updatedAnswers;
  }

  /**
   * Returns a new `Answers` object, with the `newAnswerValue` set at the path that's associated with the `nodeId`.
   * Note: `nodeId` should be used instead of answerPath (Legacy), the latter taking the form of an explicit full path to the answer's key, e.g. `insuredPeople.2.personalInfo.firstName`
   *
   * @example
   * const answers = { name: 'Joe', province: 'QC' };
   * const updatedAnswers = engine.updateAnswer(answers, 'province', 'ON'); // => { name: 'Joe', province: 'ON' }
   */
  public updateAnswer(
    answers: Answers,
    nodeId: string,
    newAnswerValue: unknown,
    effects?: EngineEffects,
    repeatedIndices?: RepeatedIndices
  ): Answers {
    return updateAnswer(
      this.questionnaire,
      this.answersResolver,
      this.visibilityDependencyMap,
      answers,
      nodeId,
      newAnswerValue,
      this.timezone,
      effects,
      repeatedIndices
    );
  }

  /**
   * Returns a `RenderingQuestionnaire` object which can be described as a questionnaire that has been evaluated with the given `answers`.
   * This method will decorate each questionnaire's node with additional properties holding the results of the following evaluations:
   * - validity of answers
   * - visibility of sections, subsections, questions, fields
   * - completion of the questionnaire
   *
   * @example
   * const answers = { name: 'Joe', province: 'QC' };
   * const renderingOptions = {
   *    renderingType: 'web',
   *    shouldValidateAllAnswers: true,
   * };
   * const renderingQuestionnaire = engine.generateRenderingQuestionnaire(answers, renderingOptions);
   */
  public generateRenderingQuestionnaire(
    answers: Answers,
    language: Language,
    text: TextGetter,
    renderingOptions: RenderingOpts
  ): RenderingQuestionnaire {
    const { renderingType, shouldValidateAllAnswers, allFieldsCompleted, loadingFields, activeSectionId } =
      renderingOptions;
    const renderingQuestionnaireGenerator = new RenderingQuestionnaireGenerator(
      this.questionnaire,
      this.answersResolver,
      text,
      language,
      renderingType,
      this.config,
      this.timezone
    );
    return renderingQuestionnaireGenerator.generate(
      answers,
      shouldValidateAllAnswers,
      loadingFields,
      allFieldsCompleted,
      activeSectionId
    );
  }

  /**
   * Returns a boolean indicating whether the step associated to the stepId is blocking
   */
  public isStepBlocking(stepId: string, answers: Answers): boolean {
    const allSubsections: Subsection[] = getAllSubsections(this.questionnaire);
    const relevantStep: BlockingSubsection | null =
      (allSubsections.find((subsection) => subsection.id === stepId) as BlockingSubsection) ?? null;

    if (!relevantStep) throw Error(`Step not found for id ${stepId}`);

    if (typeof relevantStep.blockedIf !== 'undefined') {
      const isBlocked = evaluateConditions(relevantStep.blockedIf, answers, this.answersResolver, {}, this.timezone);
      return isBlocked;
    }

    return false;
  }

  /**
   * Returns true if the questionnaire provided with the passed answers is valid and complete, false otherwise
   * @param answers Answers
   * @returns boolean
   */
  public isQuestionnaireComplete(answers: Answers, opts?: { enableBlockedIfConditions: boolean }): boolean {
    const allFieldAnswersAreValidAndComplete = areAllFieldsValidAndComplete(
      this.questionnaire,
      answers,
      this.answersResolver,
      {
        ...this.config,
        validateAllAnswers: false,
      },
      this.timezone
    );

    // TODO: DEV-13035 remove the check on blocking rules now that SSQ is deprecated
    if (!opts?.enableBlockedIfConditions) {
      return allFieldAnswersAreValidAndComplete;
    }

    const allSubsections = getAllSubsections(this.questionnaire);
    const noBlockingAnswers = allSubsections.every((subsection) => {
      if (isBlockingSubsection(subsection)) {
        const isBlocked = evaluateConditions(subsection.blockedIf, answers, this.answersResolver, {}, this.timezone);
        return !isBlocked;
      }
      return true;
    });

    return allFieldAnswersAreValidAndComplete && noBlockingAnswers;
  }

  /**
   * Returns the percentage of progress for the questionnaire provided with the passed answers
   * @param answers Answers
   * @returns number
   */
  public calculateProgress(
    answers: Answers,
    isCompleted?: boolean,
    progressOffset?: number,
    landingStepId?: string
  ): number {
    return calculateProgress(
      this.questionnaire,
      answers,
      this.answersResolver,
      progressOffset,
      this.timezone,
      isCompleted,
      landingStepId
    );
  }
}
