import _ from 'lodash';

import { Language, RenderingType, SubsectionVariant, Answers, Timezone, IAnswerResolver } from '@breathelife/types';

import { setQuestionnaireCompletion } from '../completion';
import { localizeQuestionnaire, TextGetter } from '../locale';
import { transformItemsVisibility } from '../nodeEvaluation';
import { transformItemsValidity } from '../nodeEvaluation/validIf/validity';
import { Questionnaire } from '../structure';
import { setFieldValues, transformFields, transformQuestions, transformSubsections } from './QuestionnaireTransforms';
import { RenderingQuestionnaire } from './RenderingQuestionnaire';
import { asExpandedQuestionnaire } from './RepeatableExpansion';
import { isRenderingRepeatedQuestion } from './Structure';
import { ActiveSectionId } from './navigation';
import { populateDynamicOptions } from './populateDynamicOptions';
import { setValidationFields } from './validationTransforms';

export type RenderingQuestionnaireGeneratorConfig = {
  logUnexpectedAnswers: boolean;
  logValidationErrors: boolean;
};

export const DEFAULT_CONFIG: RenderingQuestionnaireGeneratorConfig = {
  logUnexpectedAnswers: false,
  logValidationErrors: false,
};

export class RenderingQuestionnaireGenerator {
  private readonly questionnaire: Questionnaire;
  private readonly text: (value: string, params: any) => string;
  private readonly language: Language;
  private readonly renderingType: RenderingType;
  private readonly config: RenderingQuestionnaireGeneratorConfig;
  private readonly answersResolver: IAnswerResolver;
  private readonly timezone: Timezone;

  constructor(
    questionnaire: Questionnaire,
    answersResolver: IAnswerResolver,
    text: TextGetter,
    language: Language,
    renderingType: RenderingType = RenderingType.web,
    config: RenderingQuestionnaireGeneratorConfig = DEFAULT_CONFIG,
    timezone: Timezone
  ) {
    this.questionnaire = questionnaire;
    this.answersResolver = answersResolver;
    this.text = text;
    this.language = language;
    this.renderingType = renderingType;
    this.config = config;
    this.timezone = timezone;
  }

  public generate(
    answers: Answers,
    shouldValidateAllAnswers: boolean,
    loadingFields?: boolean,
    allFieldsCompleted?: boolean,
    activeSectionId?: ActiveSectionId
  ): RenderingQuestionnaire {
    const clonedQuestionnaire: Questionnaire = _.cloneDeep(this.questionnaire);
    // Localize questionnaire text.
    const localizedQuestionnaire = localizeQuestionnaire(clonedQuestionnaire, this.language);
    // Expands repeatable questions into the appropriate number of repeated questions
    const repeatedFieldsQuestionnaire = asExpandedQuestionnaire(localizedQuestionnaire, answers, this.answersResolver);

    // Populate computed option fields with values
    const withDynamicOptionsQuestionnaire = populateDynamicOptions(
      repeatedFieldsQuestionnaire,
      answers,
      this.answersResolver
    );

    // Sets the `visible` property of all items based on `visibleIf` conditions
    const visibilityEvaluatedQuestionnaire = transformItemsVisibility(
      withDynamicOptionsQuestionnaire,
      this.answersResolver,
      answers,
      this.renderingType,
      this.timezone
    );

    // Sets the `value` property of all items based on the provided answers
    setFieldValues(visibilityEvaluatedQuestionnaire, answers, this.answersResolver);

    if (!allFieldsCompleted) {
      // Checks answers against yup schema and set `validationErrorMessage` attribute as appropriate
      setValidationFields(
        visibilityEvaluatedQuestionnaire,
        shouldValidateAllAnswers,
        this.text,
        this.config.logValidationErrors,
        this.timezone,
        activeSectionId
      );
    }

    // Validity must be computed after visibility to avoid evaluating validity for hidden fields.
    const ruleValidityEvaluatedQuestionnaire = transformItemsValidity(
      visibilityEvaluatedQuestionnaire,
      answers,
      this.answersResolver,
      this.text,
      {
        shouldValidateAllAnswers,
      },
      this.timezone
    );

    const completionEvaluatedQuestionnaire = setQuestionnaireCompletion(
      ruleValidityEvaluatedQuestionnaire,
      allFieldsCompleted || false,
      loadingFields || false
    );

    transformSubsections(completionEvaluatedQuestionnaire, (subsection) => {
      if (!subsection.variant) {
        subsection.variant = SubsectionVariant.form;
      }
      return subsection;
    });

    transformQuestions(completionEvaluatedQuestionnaire, (question) => {
      if (isRenderingRepeatedQuestion(question)) {
        question.showRemoveQuestionButton = question.showRemoveQuestionButton && !allFieldsCompleted;
        question.showAddQuestionButton = question.showAddQuestionButton && !allFieldsCompleted;
      }
    });

    transformFields(completionEvaluatedQuestionnaire, (field) => {
      field.disabled = allFieldsCompleted || field.disabled;
    });

    return completionEvaluatedQuestionnaire;
  }
}
