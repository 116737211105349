import { Conditions, CollectionInstanceIdentifiers, Answers } from '@breathelife/types';

import { createStack, Stack } from '../utils';

interface EvaluationVisitor<Out, CompleteOutput> {
  visitDefault: VisitNodeFunction<any, Out>;
  visitSectionGroup?: VisitNodeFunction<any, Out>;
  visitSection?: VisitNodeFunction<any, Out>;
  visitSubsection?: VisitNodeFunction<any, Out>;
  visitQuestion?: VisitNodeFunction<any, Out>;
  visitField?: VisitNodeFunction<any, Out>;
  visitOption?: VisitNodeFunction<any, Out>;
  complete: () => CompleteOutput;
}

type VisitNodeFunction<NodeType, Out> = (navigation: VisitNavigation<Out>, props: Props<NodeType>) => Out;

type VisitNavigation<Out> = {
  visitChildren: () => void;
  childrenResults: () => Out[];
};

type Props<NodeType> = {
  node: NodeType;
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers;
  rule?: Conditions;
  answers: Answers;
};

enum NodeType {
  SectionGroup = 'SectionGroup',
  Section = 'Section',
  Subsection = 'Subsection',
  Question = 'Question',
  Field = 'Field',
  Option = 'Option',
}
type ExtractRuleFunction = (node: any) => Conditions | undefined;

class NodeEvaluationVisit<Out, Visitor extends EvaluationVisitor<Out, any>> {
  private readonly answers: Answers;
  private readonly evalVisitor: Visitor;
  private readonly extractRule?: ExtractRuleFunction;
  private readonly resultStack: Stack<Out[]> = createStack<Out[]>();

  constructor(answers: Answers, evalVisitor: Visitor, extractRule?: ExtractRuleFunction) {
    this.answers = answers;
    this.evalVisitor = evalVisitor;
    this.extractRule = extractRule;
  }

  public visitFor<Node>(
    node: Node,
    visitChildren: () => void,
    options: {
      nodeType?: NodeType;
      repeatedInstanceIdentifiers: CollectionInstanceIdentifiers;
    }
  ): void {
    const { nodeType, repeatedInstanceIdentifiers } = options;

    const visitFunctionName = `visit${nodeType}` as keyof EvaluationVisitor<unknown, unknown>;
    const visit: VisitNodeFunction<Node, Out> = this.evalVisitor[visitFunctionName] ?? this.evalVisitor.visitDefault;

    const currentResults: Out[] = this.resultStack.peek() ?? [];
    const childrenResults: Out[] = [];
    this.resultStack.push(childrenResults);

    const extractedRule = this.extractRule ? this.extractRule(node) : undefined;

    const visitResult: Out = visit(
      {
        visitChildren,
        childrenResults: () => childrenResults,
      },
      {
        node,
        repeatedInstanceIdentifiers,
        rule: extractedRule,
        answers: this.answers,
      }
    );
    currentResults.push(visitResult);

    this.resultStack.pop();
  }
}

export { NodeEvaluationVisit, VisitNodeFunction, NodeType, ExtractRuleFunction };
