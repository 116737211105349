import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  Language,
  Localizable,
  CollectionInstanceIdentifier,
  CollectionInstanceIdentifiers,
  Answers,
  IAnswerResolver,
} from '@breathelife/types';

import { localize } from '../locale';
import {
  BaseField,
  BaseQuestion,
  Field,
  isOptionField,
  isQuestion,
  isRepeatableOptionsBasedOnCollection,
  isRepeatableOptionsWithLimits,
  RepeatableQuestion,
  RepeatableQuestionnaireNode,
  RepeatableSectionGroup,
} from '../structure';
import { TransitionNode } from './Questionnaire';
import {
  RenderingBaseField,
  RenderingOptionField,
  RenderingRepeatedQuestion,
  RenderingRepeatedSectionGroup,
  RepeatableMetadata,
} from './RenderingQuestionnaire';
import { TransitionVisitor } from './TransitionVisitor';

type RepeatedQuestionnaireNode = RepeatableQuestionnaireNode & { metadata: RepeatableMetadata };

// Input types
export type ExpandableQuestionnaire = ExpandableSectionGroup[];

type ExpandableSectionGroup = ExpandableBaseSectionGroup | ExpandableRepeatedSectionGroup;
type ExpandableBaseSectionGroup = TransitionNode & {
  sections: ExpandableSection[];
};
type ExpandableRepeatedSectionGroup = ExpandableBaseSectionGroup &
  Pick<RepeatableSectionGroup, 'options'> &
  RepeatableQuestionnaireNode;

type ExpandableSection = TransitionNode & {
  subsections: ExpandableSubsection[];
};
type ExpandableSubsection = TransitionNode & {
  questions: ExpandableQuestion[];
};

type ExpandableQuestion = ExpandableBaseQuestion | ExpandableRepeatedQuestion;
type ExpandableBaseQuestion = TransitionNode & Pick<BaseQuestion, 'options'>;
type ExpandableRepeatedQuestion = ExpandableBaseQuestion &
  Pick<RepeatableQuestion, 'options'> &
  RepeatableQuestionnaireNode;

type ExpandableField = TransitionNode & Pick<BaseField, 'id'>;
type ExpandableOption = TransitionNode;

// Output types
export type ExpandedQuestionnaire = ExpandedSectionGroup[];

type ExpandedSectionGroup = ExpandedBaseSectionGroup | ExpandedRepeatedSectionGroup;
type ExpandedBaseSectionGroup = TransitionNode & {
  sections: ExpandedSection[];
};
type ExpandedRepeatedSectionGroup = ExpandedBaseSectionGroup &
  Pick<RenderingRepeatedSectionGroup, 'metadata'> &
  RepeatedQuestionnaireNode;

type ExpandedSection = TransitionNode & { subsections: ExpandedSubsection[] };
type ExpandedSubsection = TransitionNode & { questions: ExpandedQuestion[] };

type ExpandedQuestion = ExpandedBaseQuestion | ExpandedRepeatedQuestion;
type ExpandedBaseQuestion = TransitionNode & { fields: ExpandedField[] };
type ExpandedRepeatedQuestion = ExpandedBaseQuestion &
  Pick<
    RenderingRepeatedQuestion,
    'surrogateId' | 'title' | 'showAddQuestionButton' | 'showRemoveQuestionButton' | 'metadata'
  > &
  RepeatedQuestionnaireNode;

type ExpandedField = ExpandableField & Pick<RenderingBaseField, 'metadata'>;
type ExpandedOption = ExpandableOption & Pick<RenderingOptionField, 'metadata'>;

class RepeatableExpansionVisitor extends TransitionVisitor {
  private readonly answers: Answers;
  private readonly answersResolver: IAnswerResolver;
  private expandedQuestionnaire: ExpandedQuestionnaire;
  private repeatedInstanceIdentifierContext: CollectionInstanceIdentifiers;

  constructor(answers: Answers, answersResolver: IAnswerResolver) {
    super();
    this.answers = answers;
    this.answersResolver = answersResolver;
    this.expandedQuestionnaire = [];
    this.repeatedInstanceIdentifierContext = {};
  }

  public getExpandedQuestionnaire(): ExpandedQuestionnaire {
    return this.expandedQuestionnaire;
  }

  public visitQuestionnaire(questionnaire: ExpandableQuestionnaire): void {
    this.expandedQuestionnaire = questionnaire.flatMap<ExpandedSectionGroup>((sectionGroup) => {
      const expandableRepeatedSectionGroup = sectionGroup as ExpandableRepeatedSectionGroup;
      if (expandableRepeatedSectionGroup.options?.repeatable) {
        return this.expandRepeatableQuestionnaireNode(expandableRepeatedSectionGroup) as ExpandedRepeatedSectionGroup[];
      }
      return sectionGroup as ExpandedSectionGroup;
    });

    super.visitQuestionnaire(this.expandedQuestionnaire);
  }

  public visitSectionGroup(sectionGroup: ExpandableSectionGroup): void {
    if ((sectionGroup as ExpandableRepeatedSectionGroup).options?.repeatable) {
      const expandedRepeatableSectionGroup = sectionGroup as ExpandedRepeatedSectionGroup;

      this.withRepeatedInstanceIdentifierContextFor(
        expandedRepeatableSectionGroup.nodeId,
        expandedRepeatableSectionGroup.metadata.repetitionIndex,
        () => {
          expandedRepeatableSectionGroup.metadata.repeatedInstanceIdentifierContext = {
            ...this.repeatedInstanceIdentifierContext,
          };
          super.visitSectionGroup(sectionGroup);
        }
      );
    } else {
      sectionGroup.metadata = {
        repeatedInstanceIdentifierContext: { ...this.repeatedInstanceIdentifierContext },
      };
      super.visitSectionGroup(sectionGroup);
    }
  }

  protected visitSection(section: ExpandableSection): void {
    section.metadata = {
      repeatedInstanceIdentifierContext: { ...this.repeatedInstanceIdentifierContext },
    };
    super.visitSection(section);
  }

  public visitSubsection(subsection: ExpandableSubsection): void {
    subsection.metadata = {
      repeatedInstanceIdentifierContext: { ...this.repeatedInstanceIdentifierContext },
    };

    subsection.questions = subsection.questions.flatMap<ExpandedQuestion>((question) => {
      const repeatedExpandableQuestion = question as ExpandableRepeatedQuestion;
      if (repeatedExpandableQuestion.options?.repeatable) {
        return this.expandRepeatableQuestionnaireNode(repeatedExpandableQuestion) as ExpandedQuestion[];
      }
      return question as ExpandedQuestion;
    });

    super.visitSubsection(subsection);
  }

  public visitQuestion(question: ExpandableQuestion): void {
    if (question.options?.repeatable) {
      const repeatedExpandableQuestion = question as ExpandedRepeatedQuestion;

      this.visitRepeatedQuestion(repeatedExpandableQuestion);
      this.withRepeatedInstanceIdentifierContextFor(
        repeatedExpandableQuestion.nodeId,
        repeatedExpandableQuestion.metadata.repetitionIndex,
        () => {
          question.metadata = {
            ...repeatedExpandableQuestion.metadata,
            repeatedInstanceIdentifierContext: { ...this.repeatedInstanceIdentifierContext },
          };
          super.visitQuestion(question);
        }
      );
    } else {
      question.metadata = {
        repeatedInstanceIdentifierContext: { ...this.repeatedInstanceIdentifierContext },
      };
      super.visitQuestion(question);
    }
  }

  public visitRepeatedQuestion(question: ExpandableRepeatedQuestion): void {
    const expandedQuestion = question as ExpandedRepeatedQuestion;

    const index = expandedQuestion.metadata.repetitionIndex;
    const repetitions = expandedQuestion.metadata.repetitionCount;

    if (isRepeatableOptionsBasedOnCollection(expandedQuestion.options)) {
      Object.assign(expandedQuestion, {
        showRemoveQuestionButton: false,
        showAddQuestionButton: false,
      });
      return;
    }

    const hasReachedMinimumRepetitions: boolean = repetitions === expandedQuestion.options.minRepetitions;
    const isLastRepetition: boolean = index === repetitions - 1;
    const hasRepetitionsLeft: boolean = index < expandedQuestion.options.maxRepetitions - 1;

    Object.assign(expandedQuestion, {
      title: formatRepeatableQuestionTitle(expandedQuestion.title, index),
      showRemoveQuestionButton: !hasReachedMinimumRepetitions,
      showAddQuestionButton: isLastRepetition && hasRepetitionsLeft,
    });
  }

  public visitField(field: ExpandableField): void {
    const expandedField = field as ExpandedField;

    if (!expandedField.metadata?.repeatedInstanceIdentifierContext) {
      if (!expandedField.metadata) expandedField.metadata = { repeatedInstanceIdentifierContext: {} };
      expandedField.metadata.repeatedInstanceIdentifierContext = { ...this.repeatedInstanceIdentifierContext };
    }

    // Repeatable option fields rely on containing field.id-s being unique (TODO: figure out why and find a better way)
    field.id = appendRepeatableInstancesToId(field.id, expandedField.metadata.repeatedInstanceIdentifierContext);

    super.visitField(field);
  }

  protected visitOption(option: ExpandableOption): void {
    (option as ExpandedOption).metadata = {
      repeatedInstanceIdentifierContext: { ...this.repeatedInstanceIdentifierContext },
    };
  }

  private expandBasedOnAnotherCollectionAnswerCount(
    repeatableNode: RepeatableQuestionnaireNode
  ): RepeatableQuestionnaireNode[] {
    const { options } = repeatableNode;

    if (!isRepeatableOptionsBasedOnCollection(options)) {
      return [repeatableNode];
    }

    const collectionNodeId = options.expandToCollectionLength;

    if (!collectionNodeId) {
      return [repeatableNode];
    }

    const collection = this.answersResolver.getCollection(this.answers, collectionNodeId);
    if (!collection) {
      return [repeatableNode];
    }

    const selectTitleNodeIds: string[] = options.selectTitle || [];
    const selectTextNodeIds: string[] = options.selectText || [];

    const hasDefinedSelectTitle = !!options.selectTitle && options.selectTitle.length > 0;
    const hasDefinedSelectText = !!options.selectText && options.selectText.length > 0;

    const f = findSelectValues(this.answersResolver, this.answers, this.repeatedInstanceIdentifierContext);
    const titleValues = f(collectionNodeId, selectTitleNodeIds);
    const textValues = f(collectionNodeId, selectTextNodeIds);

    return collection.map((collectionItem, index) => {
      const clonedNode: RepeatableQuestionnaireNode = Object.assign(_.cloneDeep(repeatableNode), {
        id: `${repeatableNode.id}.${index}`,
        // Believe us, right here its just a string, not a localizable thing.
        // We are after the localization has been applied to `localizable` is just string here.
        // Changing the type will have to be done in another PR, hence the following ts-ignores.
        title: hasDefinedSelectTitle
          ? // @ts-ignore
            textSubstitution(repeatableNode.title, titleValues[index], '_')
          : repeatableNode.title,
        text: hasDefinedSelectText
          ? // @ts-ignore
            textSubstitution(repeatableNode.text, textValues[index], '_')
          : repeatableNode.text,
        metadata: {
          repetitionIndex: index,
          repetitionCount: collection.length,
          parentId: repeatableNode.id,
        },
      });

      if (isQuestion(repeatableNode) && isQuestion(clonedNode)) {
        clonedNode.fields = repeatableNode.fields.map((field: Field) => {
          return isOptionField(field)
            ? {
                ...field,
                options: field.options.map((option) => ({ ...option, surrogateId: collectionItem.surrogateId })),
              }
            : field;
        });
      }

      return clonedNode;
    });
  }

  private expandBasedOnMinMaxLimit(repeatableNode: RepeatableQuestionnaireNode): RepeatableQuestionnaireNode[] {
    const { nodeId, options } = repeatableNode;

    if (!isRepeatableOptionsWithLimits(options)) {
      return [repeatableNode];
    }

    const repetitionCount = this.findRepetitionsCount(nodeId, options.minRepetitions);

    return _.times(repetitionCount, (repetitionIndex: number) => {
      const surrogateId = this.surrogateIdFor(nodeId, {
        ...this.repeatedInstanceIdentifierContext,
        [nodeId]: repetitionIndex,
      });

      return Object.assign(_.cloneDeep(repeatableNode), {
        id: `${repeatableNode.id}.${repetitionIndex}`,
        surrogateId: surrogateId,
        options: {
          ...repeatableNode.options,
        },
        metadata: {
          repetitionIndex,
          repetitionCount,
          parentId: repeatableNode.id,
        },
      });
    });
  }

  private expandRepeatableQuestionnaireNode(
    repeatableNode: RepeatableQuestionnaireNode
  ): RepeatableQuestionnaireNode[] {
    const { options } = repeatableNode;

    if (isRepeatableOptionsBasedOnCollection(options)) {
      return this.expandBasedOnAnotherCollectionAnswerCount(repeatableNode);
    } else {
      return this.expandBasedOnMinMaxLimit(repeatableNode);
    }
  }

  private findRepetitionsCount(nodeId: string, minRepetitions: number): number {
    const repetitions = this.answersResolver.getRepetitionCount(
      this.answers,
      nodeId,
      this.repeatedInstanceIdentifierContext
    );

    return repetitions ?? minRepetitions;
  }

  private withRepeatedInstanceIdentifierContextFor(
    nodeId: string,
    instancePath: CollectionInstanceIdentifier,
    callback: () => void
  ): void {
    this.enterRepeatedInstanceIdentifierContext(nodeId, instancePath);
    callback();
    this.exitRepeatedInstanceIdentifierContext(nodeId);
  }

  private enterRepeatedInstanceIdentifierContext(nodeId: string, instancePath: CollectionInstanceIdentifier): void {
    this.repeatedInstanceIdentifierContext[nodeId] = instancePath;
  }

  private exitRepeatedInstanceIdentifierContext(nodeId: string): void {
    delete this.repeatedInstanceIdentifierContext[nodeId];
  }

  private surrogateIdFor(nodeId: string, repeatedInstanceIdentifierContext: CollectionInstanceIdentifiers): string {
    const existingSurrogateId = this.answersResolver.getAnswer(
      this.answers,
      nodeId,
      repeatedInstanceIdentifierContext
    )?.surrogateId;

    return existingSurrogateId || uuid();
  }
}

function findSelectValues(
  answersResolver: IAnswerResolver,
  answers: Answers,
  repeatedInstanceIdentifierContext: CollectionInstanceIdentifiers
) {
  return (collectionNodeId: string, selectTitleNodeIds: string[]) => {
    if (selectTitleNodeIds.length === 0) {
      return [];
    }

    const answersBySurrogateId = answersResolver.getRepeatedAnswers(
      answers,
      collectionNodeId,
      selectTitleNodeIds,
      repeatedInstanceIdentifierContext
    );

    if (!answersBySurrogateId) {
      return [];
    }

    return Object.keys(answersBySurrogateId).map((key) =>
      selectTitleNodeIds.map((nodeId) => answersBySurrogateId[key].answersByNodeId[nodeId])
    );
  };
}

function formatRepeatableQuestionTitle(
  title: string | Localizable | undefined,
  index: number
): string | Localizable | undefined {
  const replaceIndex = (str: string): string => str.replace('%{index}', (index + 1).toString());

  switch (typeof title) {
    case 'string':
      return replaceIndex(title);
    case 'object':
      return {
        en: replaceIndex(localize(title, Language.en) as string),
        fr: replaceIndex(localize(title, Language.fr) as string),
      };
  }
}

function appendRepeatableInstancesToId(id: string, repeatedIdentifiers: CollectionInstanceIdentifiers): string {
  const orderedIdentifiers = Object.entries(repeatedIdentifiers).sort(([aKey], [bKey]) => (aKey > bKey ? 1 : -1));

  const identifierStrings = orderedIdentifiers.map(
    ([key, value]: [string, CollectionInstanceIdentifier]) => `${key}_${value}`
  );
  return identifierStrings.length ? `${id}_${identifierStrings.join('_')}` : id;
}

function asExpandedQuestionnaire(
  questionnaire: ExpandableQuestionnaire,
  answers: Answers,
  answersResolver: IAnswerResolver
): ExpandedQuestionnaire {
  const repeatableExpansionVisitor = new RepeatableExpansionVisitor(answers, answersResolver);
  repeatableExpansionVisitor.visitQuestionnaire(questionnaire);
  return repeatableExpansionVisitor.getExpandedQuestionnaire();
}

function textSubstitution(text: string, words: string[], fallbackWord: string): string {
  if (typeof text !== 'string') {
    return fallbackWord;
  }
  const wordsWithFallback = words.map((e) => (typeof e === 'string' ? e : fallbackWord));

  let i = 0;
  return text.replace(/{}/g, function () {
    return typeof wordsWithFallback[i] != 'undefined' ? wordsWithFallback[i++] : fallbackWord;
  });
}

export { asExpandedQuestionnaire, formatRepeatableQuestionTitle, appendRepeatableInstancesToId };
