import {
  Questionnaire,
  SectionGroup,
  Section,
  Subsection,
  Question,
  Field,
  SelectOption,
  isOptionField,
} from './structure';

// Traverses a Questionnaire
// Can produce an output of the provided generic type, if no output is necessary `void` can be provided as the `Output` type.
export default abstract class QuestionnaireVisitor {
  public visitQuestionnaire(questionnaire: Questionnaire): { questionnaire: Questionnaire } {
    if (questionnaire?.length > 0) {
      for (const sectionGroup of questionnaire) {
        this.visitSectionGroup(sectionGroup);
      }
    }

    // Return `questionnaire` to support mutation if needed.
    return { questionnaire };
  }

  public visitSectionGroup(sectionGroup: SectionGroup): void {
    if (sectionGroup.sections?.length > 0) {
      for (const section of sectionGroup.sections) {
        this.visitSection(section);
      }
    }
  }

  public visitSection(section: Section): void {
    if (section.subsections?.length > 0) {
      for (const subsection of section.subsections) {
        this.visitSubsection(subsection);
      }
    }
  }

  public visitSubsection(subsection: Subsection): void {
    if (subsection.questions?.length > 0) {
      for (const question of subsection.questions) {
        this.visitQuestion(question);
      }
    }
  }

  public visitQuestion(question: Question): void {
    if (question.fields?.length > 0) {
      for (const field of question.fields) {
        this.visitField(field);
      }
    }
  }

  public visitField(field: Field): void {
    if (isOptionField(field)) {
      if (field.options?.length > 0) {
        for (const option of field.options) {
          this.visitOption(option);
        }
      }
    }
  }

  public visitOption(option: SelectOption): void {}
}
