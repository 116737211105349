import _ from 'lodash';

import { FieldTypes, isTextFieldType } from '../structure';
import {
  RenderingAgreeField,
  RenderingAutocompleteField,
  RenderingButtonField,
  RenderingField,
  RenderingInformationField,
  RenderingOptionField,
  RenderingPlaceholderField,
  RenderingQuestion,
  RenderingRepeatedQuestion,
  RenderingRepeatedSectionGroup,
  RenderingSectionGroup,
  RenderingSignatureField,
  RenderingSizedOptionField,
  RenderingTextField,
} from './RenderingQuestionnaire';

export function isRenderingTextField(field: RenderingField): field is RenderingTextField {
  return isTextFieldType(field.type);
}

export function isRenderingRadioField(field: RenderingField): field is RenderingSizedOptionField {
  return field.type === FieldTypes.radio;
}

export function isRenderingCheckboxGroupField(field: RenderingField): field is RenderingSizedOptionField {
  return field.type === FieldTypes.checkboxGroup;
}

export function isRenderingAgreeField(field: RenderingField): field is RenderingAgreeField {
  return field.type === FieldTypes.agree;
}

export function isRenderingButtonField(field: RenderingField): field is RenderingButtonField {
  return field.type === FieldTypes.button;
}

export function isRenderingAutocompleteField(field: RenderingField): field is RenderingAutocompleteField {
  return field.type === FieldTypes.autocomplete;
}

export function isRenderingPlaceholderField(field: RenderingField): field is RenderingPlaceholderField {
  return !!(field as RenderingPlaceholderField).placeholder;
}

export function isRenderingOptionField(field: RenderingField): field is RenderingOptionField {
  return !_.isEmpty((field as RenderingOptionField).options);
}

export function isRenderingSignatureField(field: RenderingSignatureField): field is RenderingSignatureField {
  return field.type === FieldTypes.signature;
}

export function isRenderingRepeatedSectionGroup(
  sectionGroup: RenderingSectionGroup
): sectionGroup is RenderingRepeatedSectionGroup {
  return _.get(sectionGroup, ['options', 'repeatable'], false);
}

export function isRenderingRepeatedQuestion(question: RenderingQuestion): question is RenderingRepeatedQuestion {
  return _.get(question, ['options', 'repeatable'], false);
}

export function isRenderingInformationField(field: RenderingField): field is RenderingInformationField {
  return field.type === FieldTypes.information;
}
