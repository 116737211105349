import { evaluateConditions } from '@breathelife/condition-engine';
import {
  Conditions,
  RenderingType,
  CollectionInstanceIdentifiers,
  Answers,
  Timezone,
  IAnswerResolver,
} from '@breathelife/types';

import { TransitionNode, TransitionNodeWithMetadata } from '../../renderingTransforms';
import { EvaluatedVisibilityNode } from '../../renderingTransforms/RenderingQuestionnaire';
import { TransformEvaluationVisitorAdapter } from '../../renderingTransforms/TransformVisitor';
import { VisibleIf } from '../../structure';
import { createVisibilityVisitor } from './VisibilityVisitor';

const isItemVisible = evaluateConditions;

type QuestionnaireNode = {
  renderingTypeFilter?: RenderingType[];
  visibleIf?: Conditions;
};

type QuestionnaireWithVisibility<VisibilityEvaluationStatus> = (TransitionNodeWithMetadata &
  VisibilityEvaluationStatus & {
    sections: (TransitionNode &
      VisibilityEvaluationStatus & {
        subsections: (TransitionNode &
          VisibilityEvaluationStatus & {
            questions: (TransitionNodeWithMetadata &
              VisibilityEvaluationStatus & {
                fields: (TransitionNodeWithMetadata &
                  VisibilityEvaluationStatus & {
                    options?: (TransitionNodeWithMetadata & VisibilityEvaluationStatus)[];
                  })[];
              })[];
          })[];
      })[];
  })[];

type UnevaluatedVisibility = VisibleIf;
type EvaluatedVisibility = EvaluatedVisibilityNode;

type UnevaluatedVisibilityQuestionnaire = QuestionnaireWithVisibility<UnevaluatedVisibility>;
export type EvaluatedVisibilityQuestionnaire = QuestionnaireWithVisibility<EvaluatedVisibility>;

function evaluateVisibility(
  node: QuestionnaireNode,
  renderingType: RenderingType,
  answersResolver: IAnswerResolver,
  answers: Answers,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
  rule?: Conditions
): boolean {
  if (node.renderingTypeFilter && !node.renderingTypeFilter.includes(renderingType)) {
    return false;
  }

  if (rule) {
    return isItemVisible(rule, answers, answersResolver, repeatedInstanceIdentifiers, timezone);
  }

  //make node visible by default
  return true;
}

function transformItemsVisibility(
  questionnaire: UnevaluatedVisibilityQuestionnaire,
  answersResolver: IAnswerResolver,
  answers: Answers,
  renderingType: RenderingType,
  timezone: Timezone
): EvaluatedVisibilityQuestionnaire {
  const visibilityVisitor = createVisibilityVisitor({
    answersResolver,
    processOutput: ({ node, isVisible }) => {
      node.visible = isVisible;

      // Assigning to `undefined` rather than using `delete` for performance reasons.
      node.visibleIf = undefined;
    },
    complete: () => undefined,
    renderingType,
    timezone,
  });
  const visitorAdapter = new TransformEvaluationVisitorAdapter(answers, visibilityVisitor, (node) => node.visibleIf);
  visitorAdapter.visitQuestionnaire(questionnaire);
  return questionnaire as EvaluatedVisibilityQuestionnaire;
}

export { evaluateVisibility, transformItemsVisibility };
