import _ from 'lodash';

import { InsuranceScopes, Language, RenderingType, Answers, Timezone } from '@breathelife/types';

import { AnswerPath, NodeIdAnswersResolver } from '../answersResolver';
import { QuestionnaireEngine } from '../external/QuestionnaireEngine';
import { TextGetter } from '../locale';
import { getAllSections, getAllSubsections } from '../questionnaire';
import { RenderingQuestionnaire, RenderingSection, RenderingSubsection } from '../renderingTransforms';
import { Questionnaire } from '../structure';

function mockText(key: string): string {
  const mapping: { [key: string]: string } = {
    'validation.pastDate': 'Please enter a date earlier than today',
    'validation.canadianPostalCode': 'Invalid postal code',
    'validation.zipCode': 'Invalid zip code',
    'validation.invalidEmail': 'Invalid email',
    'validation.phoneNumber': 'Invalid phone number',
    'validation.phoneNumberAreaCode': 'Invalid area code',
    'validation.decimal': 'Needs to be a decimal',
    'validation.isTrue': 'Needs to be true',
  };
  return mapping[key];
}

function asRenderingQuestionnaire(
  questionnaire: Questionnaire,
  answers: Answers,
  nodeIdToAnswerPathMap: Map<string, AnswerPath> = new Map(),
  text: TextGetter = mockText,
  validateAllAnswers: boolean = false,
  insuranceScopes: InsuranceScopes[] = [],
  timezone: Timezone
): RenderingQuestionnaire {
  const questionnaireEngine = new QuestionnaireEngine(
    questionnaire,
    nodeIdToAnswerPathMap,
    {
      scopes: insuranceScopes,
    },
    undefined,
    timezone
  );

  return questionnaireEngine.generateRenderingQuestionnaire(answers, Language.en, text, {
    shouldValidateAllAnswers: validateAllAnswers,
    renderingType: RenderingType.web,
  });
}

function buildAnswersFromValueMap(
  questionnaire: Questionnaire,
  answersValueMap: { [nodeId: string]: any },
  nodeIdToAnswerPathMap: Map<string, AnswerPath>,
  insuranceScopes: InsuranceScopes[] = [],
  timezone: Timezone
): Answers {
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  const answers = {};

  Object.keys(answersValueMap).forEach((nodeId) => {
    const answerValue = answersValueMap[nodeId];
    answersResolver.setAnswer(answerValue, answers, nodeId, {});
  });

  const questionnaireEngine = new QuestionnaireEngine(
    questionnaire,
    nodeIdToAnswerPathMap,
    {
      scopes: insuranceScopes,
    },
    undefined,
    timezone
  );
  const answersWithDefaultValues = questionnaireEngine.getAnswersWithDefaultValues(answers);
  return answersWithDefaultValues;
}

function getRenderingSection(
  questionnaire: Questionnaire,
  answers: Answers,
  sectionId: string,
  nodeIdToAnswerPathMap: Map<string, AnswerPath> = new Map(),
  insuranceScopes: InsuranceScopes[] = [],
  timezone: Timezone
): [RenderingSection | undefined, RenderingQuestionnaire] {
  const renderingQuestionnaire = asRenderingQuestionnaire(
    questionnaire,
    answers,
    nodeIdToAnswerPathMap,
    mockText,
    false,
    insuranceScopes,
    timezone
  );
  const sections = getAllSections(renderingQuestionnaire);
  const section = sections.find((section) => section.id === sectionId);
  return [section, renderingQuestionnaire];
}

function getRenderingSubsection(
  questionnaire: Questionnaire,
  answers: Answers,
  subsectionId: string,
  nodeIdToAnswerPathMap: Map<string, AnswerPath> = new Map(),
  insuranceScopes: InsuranceScopes[] = [],
  timezone: Timezone
): [RenderingSubsection | undefined, RenderingQuestionnaire] {
  const renderingQuestionnaire = asRenderingQuestionnaire(
    questionnaire,
    answers,
    nodeIdToAnswerPathMap,
    mockText,
    false,
    insuranceScopes,
    timezone
  );
  const subsections = getAllSubsections(renderingQuestionnaire);
  const subsection = _.find(subsections, (subsection) => subsection.id === subsectionId);
  return [subsection, renderingQuestionnaire];
}

export const TestUtils = {
  getRenderingSection,
  getRenderingSubsection,
  buildAnswersFromValueMap,
  asRenderingQuestionnaire,
};
