import { v4 as uuid } from 'uuid';

import { evaluateConditions, evaluateQuery } from '@breathelife/condition-engine';
import {
  Conditions,
  EffectValue,
  Query,
  SetValueEffect,
  SetValueEffectSpecialValue,
  CollectionInstanceIdentifiers,
  Answers,
  IAnswerResolver,
  Timezone,
} from '@breathelife/types';

import { VisibilityDependencyMap } from './nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from './nodeEvaluation/visibleIf/filterVisibleAnswers';

export function processSetValueEffects(
  setValueEffects: SetValueEffect[] | undefined,
  changedAnswer: unknown,
  answers: Answers,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone
): void {
  if (!setValueEffects) {
    return;
  }

  const changedNodeIds = setValueEffects
    .map((effect: SetValueEffect) => {
      const { at, value, newAnswerMustMatch, triggerIf } = effect;
      // eslint-disable-next-line no-debugger
      debugger;
      if (newAnswerMustMatch && !(newAnswerMustMatch === changedAnswer)) {
        return; // changedAnswer does not match, skip this effect.
      }

      if (
        triggerIf &&
        !shouldApplyEffect(triggerIf, answers, answersResolver, collectionInstanceIdentifiers, timezone)
      ) {
        return;
      }

      const valueToSet = resolveValueFromEffectValue(
        value,
        answers,
        answersResolver,
        collectionInstanceIdentifiers,
        timezone
      );

      const currentValue = answersResolver.getAnswer(answers, at, collectionInstanceIdentifiers);
      if (valueToSet !== currentValue) {
        answersResolver.setAnswer(valueToSet, answers, at, collectionInstanceIdentifiers);
        return at;
      }
    })
    .filter(Boolean) as string[];

  filterVisibleAnswersAfterEffectChanges(
    changedNodeIds,
    answers,
    answersResolver,
    dependencyMap,
    collectionInstanceIdentifiers,
    timezone
  );
}

function filterVisibleAnswersAfterEffectChanges(
  nodeIds: string[],
  answers: Answers,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone
): void {
  let updatedAnswers = answers;

  for (const nodeId of nodeIds) {
    updatedAnswers = filterVisibleAnswers(
      nodeId,
      dependencyMap,
      answersResolver,
      updatedAnswers,
      collectionInstanceIdentifiers,
      timezone
    );
  }
}

function shouldApplyEffect(
  triggerIf: Conditions,
  answers: Answers,
  answersResolver: IAnswerResolver,
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone
): boolean {
  return evaluateConditions(triggerIf, answers, answersResolver, collectionInstanceIdentifiers, timezone);
}

function resolveValueFromEffectValue(
  effectValue: EffectValue,
  answers: Answers,
  answersResolver: IAnswerResolver,
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone
): string | boolean | number | undefined {
  if (effectValue === SetValueEffectSpecialValue.newId) return uuid();

  if (isQueryEffectValue(effectValue)) {
    return evaluateQuery(effectValue, answers, answersResolver, collectionInstanceIdentifiers, timezone);
  }

  return effectValue;
}

function isQueryEffectValue(effectValue: EffectValue): effectValue is Query {
  return !!effectValue && !!(effectValue as Query).select;
}
