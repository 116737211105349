import { Answers } from '@breathelife/types';

import { NodeIdAnswersResolver } from '../answersResolver';
import { Questionnaire } from '../structure';
import { EvaluationVisitorAdapter, EvaluationVisitor } from './EvaluationVisitor';

type Options<Output> = {
  evaluationVisitor: EvaluationVisitor<any, Output>;
  ruleName: string;
  answers: Answers;
};

function evaluateRulesOn<Output>(
  questionnaire: Questionnaire,
  answersResolver: NodeIdAnswersResolver,
  { evaluationVisitor, ruleName, answers }: Options<Output>
): Output {
  const evaluationTraversalVisitor = new EvaluationVisitorAdapter(
    answers,
    answersResolver,
    evaluationVisitor,
    (node: any) => node[ruleName]
  );

  evaluationTraversalVisitor.visitQuestionnaire(questionnaire);

  return evaluationVisitor.complete();
}

export { evaluateRulesOn };
