import _ from 'lodash';

import { Condition, Localizable } from '@breathelife/types';

import { ValidityRule } from '../nodeEvaluation';
import {
  BaseNode,
  Field,
  isOptionField,
  OptionField,
  Question,
  Questionnaire,
  Section,
  SectionGroup,
  SelectOption,
  Subsection,
} from '../structure';

/** This makes every property of an object optional, including the properties of child properties */
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type PartialQuestionnaire = RecursivePartial<Questionnaire>;
export type PartialSection = RecursivePartial<Section>;
export type PartialSectionGroup = RecursivePartial<SectionGroup>;
export type PartialQuestion = RecursivePartial<Question>;
export type PartialField = RecursivePartial<Field>;
export type PartialSelectOption = RecursivePartial<SelectOption>;
export type PartialSubsection = RecursivePartial<Subsection>;
export type PartialCondition = RecursivePartial<Condition>;
export type PartialValidityRule = RecursivePartial<ValidityRule<Localizable>>;

export function overrideQuestionnaireProperties(
  questionnaire: Questionnaire,
  updatedQuestionnaire: PartialQuestionnaire
): Questionnaire {
  validateAllOverriddenNodesExist(questionnaire, _.compact(updatedQuestionnaire));

  const originalQuestionnaire = _.cloneDeep(questionnaire);

  _.forEach(originalQuestionnaire, (sectionGroup) => {
    const updatedSectionGroup = updatedQuestionnaire?.find(
      (updatedSectionGroup) => sectionGroup.id === updatedSectionGroup?.id
    );
    if (!updatedSectionGroup) return;
    overrideSectionGroup(sectionGroup, updatedSectionGroup);
  });

  return originalQuestionnaire;
}

export function overrideSectionGroup(
  sectionGroup: SectionGroup,
  updatedSectionGroup: PartialSectionGroup
): SectionGroup {
  validateAllOverriddenNodesExist(sectionGroup.sections, _.compact(updatedSectionGroup.sections));

  const updatedSectionGroupWithoutSections = _.omit(updatedSectionGroup, 'sections');

  const overriddenSectionGroup = _.merge(sectionGroup, updatedSectionGroupWithoutSections);

  _.forEach(sectionGroup.sections, (section) => {
    const updatedSection = updatedSectionGroup.sections?.find((updatedSection) => section.id === updatedSection?.id);

    if (!updatedSection) return;
    overrideSection(section, updatedSection);
  });

  return overriddenSectionGroup;
}

export function overrideSection(section: Section, updatedSection: PartialSection): Section {
  validateAllOverriddenNodesExist(section.subsections, _.compact(updatedSection.subsections));

  const updatedSectionWithoutSubsections = _.omit(updatedSection, 'subsections');
  const overriddenSection = _.merge(section, updatedSectionWithoutSubsections);

  _.forEach(section.subsections, (subsection) => {
    const updatedSubsection = updatedSection.subsections?.find(
      (updatedSubsection) => subsection.id === updatedSubsection?.id
    );

    if (!updatedSubsection) return;
    overrideSubsection(subsection, updatedSubsection);
  });

  return overriddenSection;
}

export function overrideSubsection(subsection: Subsection, updatedSubsection: PartialSubsection): Subsection {
  validateAllOverriddenNodesExist(subsection.questions, _.compact(updatedSubsection.questions));

  const updatedSubsectionWithoutQuestions = _.omit(updatedSubsection, 'questions');
  const overriddenSubsection = _.merge(subsection, updatedSubsectionWithoutQuestions);

  _.forEach(subsection.questions, (question) => {
    const updatedQuestion = updatedSubsection.questions?.find((updatedQuestion) => question.id === updatedQuestion?.id);

    if (!updatedQuestion) return;
    overrideQuestion(question, updatedQuestion);
  });

  return overriddenSubsection;
}

export function overrideQuestion(question: Question, updatedQuestion: PartialQuestion): Question {
  validateAllOverriddenNodesExist(question.fields, _.compact(updatedQuestion.fields));

  const updatedQuestionWithoutFields = _.omit(updatedQuestion, 'fields');
  const overriddenQuestion = _.merge(question, updatedQuestionWithoutFields);

  _.forEach(question.fields, (field) => {
    const updatedField = updatedQuestion.fields?.find((updatedField) => field.id === updatedField?.id);

    if (!updatedField) return;
    overrideField(field, updatedField);
  });

  return overriddenQuestion;
}

export function overrideField(field: Field, updatedField: PartialField): Field {
  const updatedFieldWithoutOptions = _.omit(updatedField, 'options');
  const overriddenField = _.merge(field, updatedFieldWithoutOptions);

  const updatedOptionField = updatedField as OptionField;

  if (isOptionField(field) && updatedOptionField.options) {
    validateAllOverriddenNodesExist(field.options, _.compact(updatedOptionField.options) as PartialSelectOption[]);

    _.forEach(field.options, (option) => {
      const updatedOption = updatedOptionField.options?.find((updatedOption) => option.id === updatedOption?.id);

      if (!updatedOption) return;

      _.merge(option, updatedOption);
    });
  }

  return overriddenField;
}

function validateAllOverriddenNodesExist<Node extends BaseNode>(
  originalNodes: Node[] | undefined,
  updatedNodes: RecursivePartial<Node>[]
): void {
  const nonExistingIds: string[] = [];
  const nodesWithoutIds: RecursivePartial<Node>[] = [];

  updatedNodes.forEach((updatedNode) => {
    if (!updatedNode.id) {
      nodesWithoutIds.push(updatedNode);
      return;
    }

    const originalNode = originalNodes?.find((node) => updatedNode.id === node?.id);

    if (!originalNode) {
      nonExistingIds.push(updatedNode.id as string);
    }
  });

  if (nonExistingIds.length) {
    throw new Error(
      `The updated questionnaire is attempting to override the following non-existing ids: ${nonExistingIds.join(', ')}`
    );
  }

  if (nodesWithoutIds.length) {
    throw new Error(
      `The updated questionnaire is attempting to override the following nodes without specifying their IDs: ${JSON.stringify(
        nodesWithoutIds
      )}`
    );
  }
}
