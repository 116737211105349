import { Answers } from '@breathelife/types';

import { NodeIdAnswersResolver } from '../answersResolver';
import { ExpandedContextVisitor, RepetitionIntervalBoundary } from '../expandedContext/ExpandedContextVisitor';
import {
  Field,
  isQuestionRepeatable,
  isSectionGroupRepeatable,
  Question,
  RepeatableQuestion,
  RepeatableQuestionnaireNode,
  RepeatableSectionGroup,
  Section,
  SectionGroup,
  SelectOption,
  Subsection,
} from '../structure';
import { ExtractRuleFunction, NodeEvaluationVisit, NodeType, VisitNodeFunction } from './NodeEvaluationVisit';

interface EvaluationVisitor<Out, CompleteOutput> {
  visitDefault: VisitNodeFunction<any, Out>;
  visitSectionGroup?: VisitNodeFunction<SectionGroup, Out>;
  visitSection?: VisitNodeFunction<Section, Out>;
  visitSubsection?: VisitNodeFunction<Subsection, Out>;
  visitQuestion?: VisitNodeFunction<Question, Out>;
  visitField?: VisitNodeFunction<Field, Out>;
  visitOption?: VisitNodeFunction<SelectOption, Out>;
  complete: () => CompleteOutput;
}

class EvaluationVisitorAdapter<Out> extends ExpandedContextVisitor {
  private readonly answers: Answers;
  private readonly answersResolver: NodeIdAnswersResolver;
  private readonly nodeEvaluationVisit: NodeEvaluationVisit<Out, EvaluationVisitor<Out, unknown>>;

  public constructor(
    answers: Answers,
    answersResolver: NodeIdAnswersResolver,
    evalVisitor: EvaluationVisitor<Out, unknown>,
    extractRule?: ExtractRuleFunction
  ) {
    super(RepetitionIntervalBoundary.maxRepetitions);
    this.nodeEvaluationVisit = new NodeEvaluationVisit(answers, evalVisitor, extractRule);
    this.answersResolver = answersResolver;
    this.answers = answers;
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    return (
      this.answersResolver.getRepetitionCount(
        this.answers,
        repeatableNode.nodeId,
        this.repeatedInstanceIdentifiers()
      ) ?? 0
    );
  }

  public visitQuestionnaire(questionnaire: SectionGroup[]): void {
    return super.visitQuestionnaire(questionnaire);
  }

  protected visitSectionGroup(sectionGroup: SectionGroup): void {
    if (!isSectionGroupRepeatable(sectionGroup)) {
      this.nodeEvaluationVisit.visitFor(sectionGroup, () => super.visitSectionGroup(sectionGroup), {
        nodeType: NodeType.SectionGroup,
        repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
      });
    } else {
      super.visitSectionGroup(sectionGroup);
    }
  }

  protected visitRepeatedSectionGroup(sectionGroup: RepeatableSectionGroup): void {
    this.nodeEvaluationVisit.visitFor(sectionGroup, () => super.visitRepeatedSectionGroup(sectionGroup), {
      nodeType: NodeType.SectionGroup,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
    });
  }

  protected visitSection(section: Section): void {
    this.nodeEvaluationVisit.visitFor(section, () => super.visitSection(section), {
      nodeType: NodeType.Section,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
    });
  }

  protected visitSubsection(subsection: Subsection): void {
    this.nodeEvaluationVisit.visitFor(subsection, () => super.visitSubsection(subsection), {
      nodeType: NodeType.Subsection,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
    });
  }

  protected visitQuestion(question: Question): void {
    if (!isQuestionRepeatable(question)) {
      this.nodeEvaluationVisit.visitFor(question, () => super.visitQuestion(question), {
        nodeType: NodeType.Question,
        repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
      });
    } else {
      super.visitQuestion(question);
    }
  }

  protected visitRepeatedQuestion(question: RepeatableQuestion): void {
    this.nodeEvaluationVisit.visitFor(question, () => super.visitRepeatedQuestion(question), {
      nodeType: NodeType.Question,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
    });
  }

  protected visitField(field: Field): void {
    this.nodeEvaluationVisit.visitFor(field, () => super.visitField(field), {
      nodeType: NodeType.Field,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
    });
  }

  protected visitOption(option: SelectOption): void {
    this.nodeEvaluationVisit.visitFor(option, () => super.visitOption(option), {
      nodeType: NodeType.Option,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers(),
    });
  }
}

export { EvaluationVisitor, EvaluationVisitorAdapter, VisitNodeFunction };
