import { AnswerPath, Path } from './AnswerPath';

export type NodeIdToAnswerPathMap = Map<string, AnswerPath>;

export type AnswerPathTreeNode = AnswerPathSingleTreeNode | AnswerPathCollectionTreeNode | AnswerPathLeafNode;

type AnswerPathSingleTreeNode = {
  path: Path;
  children: AnswerPathTreeNode[];
  nodeId?: string;
};

type AnswerPathLeafNode = {
  path: Path;
  nodeId: string;
};

export type AnswerPathCollectionTreeNode = {
  path: Path;
  children: AnswerPathTreeNode[];
  nodeId: string;
  isCollection: true;
};

export function makeNodeIdToAnswerPathMap(nodePaths: AnswerPathTreeNode[]): NodeIdToAnswerPathMap {
  const nodeIdToAnswerPaths: NodeIdToAnswerPathMap = new Map<string, AnswerPath>();
  for (const pathTreeNode of nodePaths) {
    addMappingForSubTree(pathTreeNode, nodeIdToAnswerPaths);
  }

  return new Map(nodeIdToAnswerPaths);
}

function addMappingForSubTree(
  pathTreeNode: AnswerPathTreeNode,
  knownNodeIdToAnswerPaths: NodeIdToAnswerPathMap,
  parent?: AnswerPath
): void {
  const { path, nodeId } = pathTreeNode;
  const isCollection = isAnswerPathCollectionTreeNode(pathTreeNode);
  const answerPath = new AnswerPath({ path, parent, isCollection, nodeId });

  if (pathTreeNode.nodeId) {
    if (knownNodeIdToAnswerPaths.has(pathTreeNode.nodeId)) {
      throw Error(`${pathTreeNode.nodeId} already exists in NodeIdToAnswerPathMap`);
    }

    knownNodeIdToAnswerPaths.set(pathTreeNode.nodeId, answerPath);
  }

  if (!isAnswerPathLeafNode(pathTreeNode)) {
    for (const child of pathTreeNode.children) {
      addMappingForSubTree(child, knownNodeIdToAnswerPaths, answerPath);
    }
  }
}

export function isAnswerPathCollectionTreeNode(treeNode: AnswerPathTreeNode): treeNode is AnswerPathCollectionTreeNode {
  return (treeNode as AnswerPathCollectionTreeNode).isCollection;
}

export function isAnswerPathLeafNode(treeNode: AnswerPathTreeNode): treeNode is AnswerPathLeafNode {
  return !!treeNode.nodeId && typeof (treeNode as AnswerPathCollectionTreeNode).children === 'undefined';
}
