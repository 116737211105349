export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export interface Stack<T> {
  push(...items: T[]): number;
  pop(): T | undefined;
  peek(): T | undefined;
}

export function createStack<T>(): Stack<T> {
  const _stack: T[] = [];

  return {
    push(...items: T[]) {
      return _stack.push(...items);
    },
    pop(): T | undefined {
      return _stack.pop();
    },
    peek(): T | undefined {
      return _stack[_stack.length - 1];
    },
  };
}
