import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import luhn from 'luhn-generator';
import * as yup from 'yup';

import { Language, RenderingType, Answers, Timezone } from '@breathelife/types';

import { NodeIdAnswersResolver } from './answersResolver';
import { getAllFieldsInSubsection } from './expandedContext/retrieveNodes';
import { TextGetter } from './locale';
import { phoneAreaCodesByRegion } from './phone';
import { RenderingQuestionnaireGenerator, RenderingSubsection } from './renderingTransforms';
import { Questionnaire } from './structure';

dayjs.extend(utc);
dayjs.extend(timezone);

export type ValidationError = {
  message: string;
};

export enum Validations {
  string = 'string',
  integer = 'integer',
  boolean = 'boolean',
  booleanTrue = 'booleanTrue',
  percentage = 'percentage',
  decimal = 'decimal',
  firstName = 'firstName',
  lastName = 'lastName',
  middleName = 'middleName',
  fullName = 'fullName',
  email = 'email',
  sin = 'sin',
  ssn = 'ssn',
  phoneNumber = 'phoneNumber',
  zipCode = 'zipCode',
  canadianPostalCode = 'canadianPostalCode',
  date = 'date',
  pastDate = 'pastDate',
  futureDate = 'futureDate',
  pastYear = 'pastYear',
  yearMonth = 'yearMonth',
  yearMonthPastDate = 'yearMonthPastDate',
  yearMonthFutureDate = 'yearMonthFutureDate',
  yearMonthPastOrCurrentDate = 'yearMonthPastOrCurrentDate',
  yearMonthFutureOrCurrentDate = 'yearMonthFutureOrCurrentDate',
  futureOrCurrentDate = 'futureOrCurrentDate',
  pastOrCurrentDate = 'pastOrCurrentDate',
  currentDate = 'currentDate',
  withdrawalDay = 'withdrawalDay',
  branchNumber = 'branchNumber',
  institutionNumber = 'institutionNumber',
  accountNumber = 'accountNumber',
  advisorCode = 'advisorCode',
  commissionAgentCode = 'commissionAgentCode',
  mixed = 'mixed',
}

const COUNTRY_CODE_PHONE_LENGTH = 11;
// Source https://regex101.com/r/wUJRj6/15
const nameRegex =
  /^((([a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]([\.]?))+?)(([\s'-][a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]([\.]?))+)*?){0,}$/;
const dateFormat = 'YYYY-MM-DD';
const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/; // 12345-1234
const canadianPostalCodeRegex = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;
export const phoneNumberRegex = /^1?([0-9]{3})([0-9]{3}[0-9]{4})$|^(1-)?([0-9]{3})-([0-9]{3}-[0-9]{4})$|^$/;
export const emailRegex = /^(([a-zA-Z0-9.+%$^&*=#!'`/?{}~_-]+)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
const yearMonthRegex = /^(20\d{2}|19\d{2})-(1[0-2]|0[1-9])$/;
const sinLengthRegex = /^\d{9}$/;
const branchNumberRegex = /^\d{5}$/;
const institutionNumberRegex = /^\d{3}$/;
const accountNumberRegex = /^\d{7,}$/;
const ssnRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
export const phoneAreaCodeList: number[] = _.flatten(Object.values(phoneAreaCodesByRegion));

function checkDateAndDateFormat(value: Date, originalValue: string): Date {
  // dayjs().isValid does not cover some use cases, such as selecting a date like the 31st of February
  // the reason why is because given 1999/02/31, dayjs will take the 3 extra days between the 28th and the 31st,
  // and add them to the next month, so the date it uses will be transformed to 1999/03/03
  // the workaround below is taken from this issue comment: https://github.com/iamkun/dayjs/issues/320#issuecomment-537885327
  // we create a date using the value and format provided, and then transform it back to a string using the same format
  // if it doesn't match the original string, that means that dayjs performed a manipulation on the date,
  // and isn't using the original date value
  const isDateAndDateFormatValid = dayjs(originalValue, dateFormat).format(dateFormat) === originalValue;
  if (isDateAndDateFormatValid) return value;
  const invalidDate = new Date('');
  return invalidDate;
}

function dateSchemaWithFormat(text: TextGetter): yup.DateSchema {
  return yup
    .date()
    .transform(checkDateAndDateFormat)
    .transform(replaceWithUndefinedIfOriginalIsEmptyString)
    .typeError(text('validation.dateFormat'));
}

export function stringWithEmptyAllowedIfOptional(isOptional: boolean): yup.StringSchema {
  const stringSchema = yup.string().trim();
  if (isOptional) {
    return stringSchema.transform(replaceEmptyStringOrNullWithUndefined);
  }
  return stringSchema;
}

function replaceWithUndefinedIfOriginalIsEmptyString(currentValue: any, originalValue: any): string | undefined {
  return originalValue === '' ? undefined : currentValue;
}

function replaceEmptyStringOrNullWithUndefined(currentValue: any): string | undefined {
  return currentValue === '' || currentValue === null ? undefined : currentValue;
}

type AnswersValidationOptions = {
  validateAllAnswers: boolean;
  logUnexpectedAnswers: boolean;
  logValidationErrors: boolean;
};

const defaultAnswersValidationOptions: AnswersValidationOptions = {
  validateAllAnswers: false,
  logUnexpectedAnswers: false,
  logValidationErrors: false,
};

const isPastDate = (timezone: Timezone) => {
  return (value: unknown): boolean => {
    if (value === null || value === undefined) return true;
    return dayjs.tz(dayjs(String(value)), timezone.name).isBefore(dayjs.tz(dayjs(), timezone.name));
  };
};

const isPastOrCurrentDate = (timezone: Timezone) => {
  return (value: unknown): boolean => {
    if (value === null || value === undefined) return true;

    const givenDate = dayjs.tz(dayjs(String(value)), timezone.name);
    const currentDate = dayjs.tz(dayjs(), timezone.name);
    return givenDate.isBefore(currentDate) || givenDate.isSame(currentDate, 'month');
  };
};

const isYearMonthFutureDate = (timezone: Timezone) => {
  return (value: unknown): boolean => {
    if (value === null || value === undefined) return true;

    const givenMonth = dayjs.tz(dayjs(String(value)), timezone.name);
    const currentMonth = dayjs.tz(dayjs().startOf('month'), timezone.name);
    return !givenMonth.isBefore(currentMonth);
  };
};

const isYearMonthFutureOrCurrentDate = (timezone: Timezone) => {
  return (value: unknown): boolean => {
    if (value === null || value === undefined) return true;

    const givenDate = dayjs.tz(dayjs(String(value)), timezone.name);
    const currentDate = dayjs.tz(dayjs().startOf('month'), timezone.name);
    return !givenDate.isBefore(currentDate) || givenDate.isSame(currentDate);
  };
};

// We need to generate a "rendering questionnaire" here because it conveniently
// deals with all concerns related to visibility, business rules and yup validations.
// This is _not_ a view concern in this case, and as such `renderingQuestionnaireGenerator`
// will need to have a better name (or refactor some of it).
export function areAllFieldsValidAndComplete(
  questionnaire: Questionnaire,
  answers: Answers,
  answersResolver: NodeIdAnswersResolver,
  options: AnswersValidationOptions = defaultAnswersValidationOptions,
  timezone: Timezone
): boolean {
  // throw new Error(`Timezone is: ${process.env.TZ}, date is: ${new Date()}`);
  const renderingQuestionnaireGenerator = new RenderingQuestionnaireGenerator(
    questionnaire,
    answersResolver,
    () => '',
    Language.en,
    RenderingType.web,
    { logUnexpectedAnswers: options.logUnexpectedAnswers, logValidationErrors: options.logValidationErrors },
    timezone
  );
  const renderingQuestionnaire = renderingQuestionnaireGenerator.generate(answers, options.validateAllAnswers);
  return !!renderingQuestionnaire.length && _.every(renderingQuestionnaire, (sectionGroup) => sectionGroup.completed);
}

export type FieldValidationSchemas = {
  required: { [T in Validations]: yup.MixedSchema };
  optional: { [T in Validations]: yup.MixedSchema };
};

export function makeFieldValidationSchemas(text: TextGetter, timezone: Timezone): FieldValidationSchemas {
  const schemas: FieldValidationSchemas = {
    required: makeOptionalOrRequiredFieldValidationSchemas(false, text, timezone),
    optional: makeOptionalOrRequiredFieldValidationSchemas(true, text, timezone),
  };

  return schemas;
}

function makeOptionalOrRequiredFieldValidationSchemas(
  isOptional: boolean,
  text: TextGetter,
  timezone: Timezone
): { [T in Validations]: yup.MixedSchema } {
  return {
    [Validations.string]: fieldValidation(Validations.string, isOptional, text),
    [Validations.integer]: fieldValidation(Validations.integer, isOptional, text),
    [Validations.boolean]: fieldValidation(Validations.boolean, isOptional, text),
    [Validations.booleanTrue]: fieldValidation(Validations.booleanTrue, isOptional, text),
    [Validations.percentage]: fieldValidation(Validations.percentage, isOptional, text),
    [Validations.decimal]: fieldValidation(Validations.decimal, isOptional, text),
    [Validations.firstName]: fieldValidation(Validations.firstName, isOptional, text),
    [Validations.lastName]: fieldValidation(Validations.lastName, isOptional, text),
    [Validations.middleName]: fieldValidation(Validations.middleName, isOptional, text),
    [Validations.fullName]: fieldValidation(Validations.fullName, isOptional, text),
    [Validations.email]: fieldValidation(Validations.email, isOptional, text),
    [Validations.sin]: fieldValidation(Validations.sin, isOptional, text),
    [Validations.ssn]: fieldValidation(Validations.ssn, isOptional, text),
    [Validations.phoneNumber]: fieldValidation(Validations.phoneNumber, isOptional, text),
    [Validations.zipCode]: fieldValidation(Validations.zipCode, isOptional, text),
    [Validations.canadianPostalCode]: fieldValidation(Validations.canadianPostalCode, isOptional, text),
    [Validations.date]: dateFieldValidation(Validations.date, isOptional, text, timezone),
    [Validations.pastDate]: dateFieldValidation(Validations.pastDate, isOptional, text, timezone),
    [Validations.futureDate]: dateFieldValidation(Validations.futureDate, isOptional, text, timezone),
    [Validations.pastYear]: dateFieldValidation(Validations.pastYear, isOptional, text, timezone),
    [Validations.yearMonth]: dateFieldValidation(Validations.yearMonth, isOptional, text, timezone),
    [Validations.yearMonthPastDate]: dateFieldValidation(Validations.yearMonthPastDate, isOptional, text, timezone),
    [Validations.yearMonthPastOrCurrentDate]: dateFieldValidation(
      Validations.yearMonthPastOrCurrentDate,
      isOptional,
      text,
      timezone
    ),
    [Validations.yearMonthFutureOrCurrentDate]: dateFieldValidation(
      Validations.yearMonthFutureOrCurrentDate,
      isOptional,
      text,
      timezone
    ),
    [Validations.withdrawalDay]: fieldValidation(Validations.withdrawalDay, isOptional, text),
    [Validations.branchNumber]: fieldValidation(Validations.branchNumber, isOptional, text),
    [Validations.institutionNumber]: fieldValidation(Validations.institutionNumber, isOptional, text),
    [Validations.accountNumber]: fieldValidation(Validations.accountNumber, isOptional, text),
    [Validations.advisorCode]: fieldValidation(Validations.advisorCode, isOptional, text),
    [Validations.commissionAgentCode]: fieldValidation(Validations.commissionAgentCode, isOptional, text),
    [Validations.yearMonthFutureDate]: dateFieldValidation(Validations.yearMonthFutureDate, isOptional, text, timezone),
    [Validations.mixed]: fieldValidation(Validations.mixed, isOptional, text),
    [Validations.pastOrCurrentDate]: dateFieldValidation(Validations.pastOrCurrentDate, isOptional, text, timezone),
    [Validations.futureOrCurrentDate]: dateFieldValidation(Validations.futureOrCurrentDate, isOptional, text, timezone),
    [Validations.currentDate]: dateFieldValidation(Validations.currentDate, isOptional, text, timezone),
  };
}

const getCurrentDateInTimezone = (timezone: Timezone): dayjs.Dayjs => {
  const targetTimezone = timezone.name;

  const convertedDate = dayjs.utc().tz(targetTimezone);

  return convertedDate;
};

export function dateFieldValidation(
  validation: Validations,
  isOptional: boolean,
  text: TextGetter,
  timezone: Timezone
): yup.MixedSchema {
  let schema: yup.MixedSchema | yup.StringSchema | yup.NumberSchema | yup.BooleanSchema | yup.DateSchema;

  switch (validation) {
    case Validations.date:
      schema = dateSchemaWithFormat(text);
      break;
    case Validations.yearMonth:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(yearMonthRegex, text('validation.yearMonth'));
      break;
    case Validations.pastDate:
      schema = dateSchemaWithFormat(text).max(
        getCurrentDateInTimezone(timezone).subtract(1, 'day').format(dateFormat),
        text('validation.pastDate')
      );
      break;
    case Validations.pastYear:
      schema = dateSchemaWithFormat(text)
        .min(getCurrentDateInTimezone(timezone).subtract(1, 'year').format(dateFormat), text('validation.pastYear'))
        .max(getCurrentDateInTimezone(timezone).format(dateFormat), text('validation.pastYear'));
      break;
    case Validations.pastOrCurrentDate:
      schema = dateSchemaWithFormat(text).max(
        getCurrentDateInTimezone(timezone).format(dateFormat),
        text('validation.pastOrCurrentDate')
      );
      break;
    case Validations.futureOrCurrentDate:
      schema = dateSchemaWithFormat(text).min(
        getCurrentDateInTimezone(timezone).format(dateFormat),
        text('validation.futureOrCurrentDate')
      );
      break;
    case Validations.currentDate:
      schema = dateSchemaWithFormat(text)
        .min(getCurrentDateInTimezone(timezone).format(dateFormat), text('validation.currentDate'))
        .max(getCurrentDateInTimezone(timezone).format(dateFormat), text('validation.currentDate'));
      break;
    case Validations.futureDate:
      schema = schema = dateSchemaWithFormat(text).min(
        getCurrentDateInTimezone(timezone).add(1, 'day').format(dateFormat),
        text('validation.futureDate')
      );
      break;
    case Validations.yearMonthPastDate:
      schema = stringWithEmptyAllowedIfOptional(isOptional)
        .test({ test: isPastDate(timezone), message: text('validation.pastDate') })
        .matches(yearMonthRegex, text('validation.yearMonth'));
      break;
    case Validations.yearMonthPastOrCurrentDate:
      schema = stringWithEmptyAllowedIfOptional(isOptional)
        .test({ test: isPastOrCurrentDate(timezone), message: text('validation.pastOrCurrentDate') })
        .matches(yearMonthRegex, text('validation.yearMonth'));
      break;
    case Validations.yearMonthFutureDate:
      schema = stringWithEmptyAllowedIfOptional(isOptional)
        .test({ test: isYearMonthFutureDate(timezone), message: text('validation.futureOrCurrentDate') })
        .matches(yearMonthRegex, text('validation.yearMonth'));
      break;
    case Validations.yearMonthFutureOrCurrentDate:
      schema = stringWithEmptyAllowedIfOptional(isOptional)
        .test({ test: isYearMonthFutureOrCurrentDate(timezone), message: text('validation.futureOrCurrentDate') })
        .matches(yearMonthRegex, text('validation.yearMonth'));
      break;
    default:
      schema = yup.mixed();
      break;
  }

  return (isOptional ? schema : schema.required(text('validation.required'))) as yup.MixedSchema;
}

export function fieldValidation(validation: Validations, isOptional: boolean, text: TextGetter): yup.MixedSchema {
  let schema: yup.MixedSchema | yup.StringSchema | yup.NumberSchema | yup.BooleanSchema | yup.DateSchema;

  switch (validation) {
    case Validations.string:
      schema = stringWithEmptyAllowedIfOptional(isOptional);
      break;
    case Validations.sin:
      schema = stringWithEmptyAllowedIfOptional(isOptional)
        .matches(sinLengthRegex, text('validation.sinLength'))
        .test({
          test: (value) => isValidSin(value),
          message: text('validation.sin'),
        });
      break;
    case Validations.ssn:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(ssnRegex, text('validation.ssnLength'));
      break;
    case Validations.zipCode:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(zipCodeRegex, text('validation.zipCode'));
      break;
    case Validations.canadianPostalCode:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(
        canadianPostalCodeRegex,
        text('validation.canadianPostalCode')
      );
      break;

    case Validations.firstName:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(nameRegex, {
        message: text('validation.invalidFirstName'),
      });
      break;
    case Validations.lastName:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(nameRegex, {
        message: text('validation.invalidLastName'),
      });
      break;
    case Validations.middleName:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(nameRegex, {
        message: text('validation.invalidMiddleName'),
      });
      break;
    case Validations.fullName:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(nameRegex, {
        message: text('validation.invalidFullName'),
      });
      break;
    case Validations.email:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(emailRegex, {
        message: text('validation.invalidEmail'),
      });
      break;
    case Validations.phoneNumber:
      schema = stringWithEmptyAllowedIfOptional(isOptional)
        .matches(phoneNumberRegex, text('validation.phoneNumber'))
        .test({
          test: (value) => isValidPhoneNumberAreaCode(value, phoneAreaCodeList),
          message: text('validation.phoneNumberAreaCode'),
        });
      break;
    case Validations.boolean:
      schema = yup.boolean().transform(replaceWithUndefinedIfOriginalIsEmptyString);
      break;
    case Validations.integer:
      schema = yup.number().transform(replaceWithUndefinedIfOriginalIsEmptyString).integer();
      if (isOptional) schema = schema.transform(replaceEmptyStringWithZero); // Allow empty strings
      break;
    case Validations.decimal:
      schema = yup
        .number()
        .transform(replaceCommaWithDot) // Support comma as decimal separator
        .transform(replaceWithUndefinedIfOriginalIsEmptyString)
        .test({ test: isValidDecimal(), message: text('validation.decimal') });
      if (isOptional) schema = schema.transform(replaceEmptyStringWithZero); // Allow empty strings
      break;
    case Validations.percentage:
      schema = yup
        .number()
        .transform(replaceCommaWithDot) // Support comma as decimal separator
        .transform(replaceWithUndefinedIfOriginalIsEmptyString)
        .min(0)
        .max(100)
        .test({ test: isValidDecimal(), message: text('validation.decimal') });
      if (isOptional) schema = schema.transform(replaceEmptyStringWithZero); // Allow empty strings
      break;
    case Validations.withdrawalDay:
      const minimumValue = 1;
      const maximumValue = 30;
      schema = yup.number().min(minimumValue).max(maximumValue);

      // Allows empty string by replacing it with a valid schema value
      if (isOptional) schema = schema.transform(replaceEmptyStringWith(minimumValue));
      break;
    case Validations.branchNumber:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(branchNumberRegex, text('validation.branchNumber'));
      break;
    case Validations.institutionNumber:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(
        institutionNumberRegex,
        text('validation.institutionNumber')
      );
      break;
    case Validations.accountNumber:
      schema = stringWithEmptyAllowedIfOptional(isOptional).matches(
        accountNumberRegex,
        text('validation.accountNumber')
      );
      break;
    case Validations.advisorCode:
      schema = stringWithEmptyAllowedIfOptional(isOptional).min(3).max(3);
      break;
    case Validations.commissionAgentCode:
      schema = stringWithEmptyAllowedIfOptional(isOptional).min(3).max(3);
      break;
    case Validations.booleanTrue:
      schema = yup.boolean().test({ test: isTrue, message: text('validation.isTrue') });
      break;
    case Validations.mixed:
      schema = yup.mixed();
      break;
    default:
      schema = yup.mixed();
      break;
  }

  return (isOptional ? schema : schema.required(text('validation.required'))) as yup.MixedSchema;
}

function isValidDecimal(numberOfDecimals = 2): yup.TestFunction {
  return function (value: unknown): boolean {
    if (value === null || value === undefined) return true;

    const decimals = Number(value).toString().split('.')[1];
    return decimals ? decimals.length <= numberOfDecimals : true;
  };
}

function isTrue(value: any): boolean {
  return value === true;
}

export function isValidSin(sin: string): boolean {
  if (_.isEmpty(sin)) return true; // needed for optional fields

  const sinNumber = Number(sin);
  return !isNaN(sinNumber) && luhn.validate(sinNumber);
}

export function isValidPhoneNumberAreaCode(phoneNumber: string, phoneAreaCodeList: number[]): boolean {
  if (_.isEmpty(phoneNumber)) return true; // needed for optional fields

  const digitOnlyPhoneNumber = phoneNumber.toString().replace(/[^0-9]/g, '');

  const hasCountryCode = digitOnlyPhoneNumber.length === COUNTRY_CODE_PHONE_LENGTH;
  const areaCode = digitOnlyPhoneNumber.substr(hasCountryCode ? 1 : 0, 3);

  return phoneAreaCodeList.includes(Number(areaCode));
}

function replaceEmptyStringWithZero(currentValue: any, originalValue: any): number {
  return originalValue === '' ? 0 : currentValue;
}

function replaceEmptyStringWith(replacementValue: any): (currentValue: any, originalValue: any) => any {
  return (currentValue: any, originalValue: any) => {
    return originalValue === '' ? replacementValue : currentValue;
  };
}

function replaceCommaWithDot(currentValue: any, originalValue: any): number {
  if (typeof currentValue !== 'number') {
    throw Error('Unsupported input type');
  }

  // Only parse values with numbers and commas
  // Otherwise parseFloat on non-numeric characters will often work and we'll lose validation against them
  const validCharacters = /^[0-9,]+$/;
  const originalString = originalValue.toString();

  // Values with a comma will be parsed by yup.number() as NaN, so ignore non-NaN current values
  if (isNaN(currentValue) && validCharacters.test(originalString)) {
    try {
      const parsedValue = parseFloat(originalString.replace(',', '.'));
      if (!isNaN(parsedValue)) return parsedValue;
    } catch (e) {}
  }
  return currentValue;
}

type FieldValidationDetails = {
  fieldId: string;
  isValid: boolean;
  error?: ValidationError;
};

export function getFieldValidations(node: RenderingSubsection): FieldValidationDetails[] {
  const allFields = getAllFieldsInSubsection(node as RenderingSubsection);

  return allFields.map((field) => ({
    fieldId: field.id,
    isValid: field.valid ?? false,
    error: field.validationError,
  }));
}
