import { Answers } from '@breathelife/types';

import {
  ExtractRuleFunction,
  NodeEvaluationVisit,
  NodeType,
  VisitNodeFunction,
} from '../nodeEvaluation/NodeEvaluationVisit';
import {
  TransitionField,
  TransitionOption,
  TransitionQuestion,
  TransitionQuestionnaire,
  TransitionSection,
  TransitionSectionGroup,
  TransitionSubsection,
} from './Questionnaire';
import { TransitionVisitor } from './TransitionVisitor';

interface TransformEvaluationVisitor<Out, CompleteOutput> {
  visitDefault: VisitNodeFunction<any, Out>;
  visitSectionGroup?: VisitNodeFunction<TransitionSectionGroup, Out>;
  visitSection?: VisitNodeFunction<TransitionSection, Out>;
  visitSubsection?: VisitNodeFunction<TransitionSubsection, Out>;
  visitQuestion?: VisitNodeFunction<TransitionQuestion, Out>;
  visitField?: VisitNodeFunction<TransitionField, Out>;
  visitOption?: VisitNodeFunction<TransitionOption, Out>;
  complete: () => CompleteOutput;
}

class TransformEvaluationVisitorAdapter<Out> extends TransitionVisitor {
  private readonly nodeEvaluationVisit: NodeEvaluationVisit<Out, TransformEvaluationVisitor<Out, unknown>>;

  public constructor(
    answers: Answers,
    evalVisitor: TransformEvaluationVisitor<Out, unknown>,
    extractRule?: ExtractRuleFunction
  ) {
    super();
    this.nodeEvaluationVisit = new NodeEvaluationVisit(answers, evalVisitor, extractRule);
  }

  public visitQuestionnaire(questionnaire: TransitionQuestionnaire): void {
    return super.visitQuestionnaire(questionnaire);
  }

  protected visitSectionGroup(sectionGroup: TransitionSectionGroup): void {
    this.nodeEvaluationVisit.visitFor(sectionGroup, () => super.visitSectionGroup(sectionGroup), {
      nodeType: NodeType.SectionGroup,
      repeatedInstanceIdentifiers: sectionGroup.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitSection(section: TransitionSection): void {
    this.nodeEvaluationVisit.visitFor(section, () => super.visitSection(section), {
      nodeType: NodeType.Section,
      repeatedInstanceIdentifiers: section.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitSubsection(subsection: TransitionSubsection): void {
    this.nodeEvaluationVisit.visitFor(subsection, () => super.visitSubsection(subsection), {
      nodeType: NodeType.Subsection,
      repeatedInstanceIdentifiers: subsection.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitQuestion(question: TransitionQuestion): void {
    this.nodeEvaluationVisit.visitFor(question, () => super.visitQuestion(question), {
      nodeType: NodeType.Question,
      repeatedInstanceIdentifiers: question.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitField(field: TransitionField): void {
    this.nodeEvaluationVisit.visitFor(field, () => super.visitField(field), {
      nodeType: NodeType.Field,
      repeatedInstanceIdentifiers: field.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitOption(option: TransitionOption): void {
    this.nodeEvaluationVisit.visitFor(option, () => super.visitOption(option), {
      nodeType: NodeType.Option,
      repeatedInstanceIdentifiers: option.metadata.repeatedInstanceIdentifierContext,
    });
  }
}

export { TransformEvaluationVisitorAdapter };
