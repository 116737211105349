import _ from 'lodash';

import { EngineEffects, Answers, Timezone, IAnswerResolver } from '@breathelife/types';

import { RepeatedIndices } from './answers';
import { computedQuestionnaireAnswers } from './computedAnswers';
import { defaultQuestionnaireAnswers } from './defaultAnswers';
import { processSetValueEffects } from './effects';
import { formatAnswers } from './formatAnswers';
import { VisibilityDependencyMap } from './nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from './nodeEvaluation/visibleIf/filterVisibleAnswers';
import { Questionnaire } from './structure';

export function updateAnswer(
  questionnaire: Questionnaire,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  previousAnswers: Answers,
  nodeId: string,
  changedAnswer: unknown,
  timezone: Timezone,
  effects?: EngineEffects,
  indices?: RepeatedIndices
): Answers {
  // Build collection identifiers from repeatable items in the questionnaire (section and question).
  const collectionIdentifiers: Record<string, number> = { ...indices };

  let updatedAnswers: Answers = _.cloneDeep(previousAnswers);

  processSetValueEffects(
    effects?.beforeValueUpdate,
    changedAnswer,
    updatedAnswers,
    answersResolver,
    dependencyMap,
    collectionIdentifiers,
    timezone
  );

  answersResolver.setAnswer(changedAnswer, updatedAnswers, nodeId, collectionIdentifiers);
  updatedAnswers = formatAnswers(questionnaire, answersResolver, updatedAnswers);

  // Changing this answer could affect the visibility of other answers.
  updatedAnswers = filterVisibleAnswers(
    nodeId,
    dependencyMap,
    answersResolver,
    updatedAnswers,
    collectionIdentifiers,
    timezone
  );

  processSetValueEffects(
    effects?.afterValueUpdate,
    changedAnswer,
    updatedAnswers,
    answersResolver,
    dependencyMap,
    collectionIdentifiers,
    timezone
  );

  updatedAnswers = defaultQuestionnaireAnswers(questionnaire, answersResolver, dependencyMap, updatedAnswers, timezone);
  updatedAnswers = computedQuestionnaireAnswers(
    questionnaire,
    answersResolver,
    dependencyMap,
    updatedAnswers,
    timezone
  );

  return updatedAnswers;
}
