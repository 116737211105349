import _ from 'lodash';

import { PdfDocumentType, Localizable } from '@breathelife/types';

import {
  AgreeField,
  BaseField,
  BaseNode,
  BaseQuestion,
  Field,
  FieldTypes,
  isAgreeField,
  isOptionField,
  isQuestionRepeatable,
  isReadOnlyField,
  isRepeatableOptionsBasedOnCollection,
  isSectionGroupRepeatable,
  OptionField,
  Question,
  Questionnaire,
  readOnlyFieldTypes,
  RepeatableOptions,
  RepeatableOptionsBasedOnCollection,
  RepeatableOptionsWithLimits,
  RepeatableQuestion,
  RepeatableSectionGroup,
  Section,
  SectionGroup,
  SelectOption,
  Subsection,
  supportedReadOnlyFieldTypes,
} from './structure';
import { Validations } from './validations';

function validateIsString<T>(propertyKey: keyof T, property: string): boolean {
  if (typeof property !== 'string') {
    throw Error(`property ${propertyKey} must be defined as a string`);
  }
  return true;
}

function validateIsNumber<T>(propertyKey: keyof T, property: number): boolean {
  if (typeof property !== 'number') {
    throw Error(`property ${propertyKey} must be defined as a number`);
  }
  return true;
}

function validateIsArrayOfType<T>(thing: T, key: keyof T, type: 'string' | 'number'): boolean {
  const value = thing[key];
  if (!Array.isArray(value)) {
    throw Error(`property ${key} must be defined as an array`);
  }

  if (value.every((e) => typeof e === type)) {
    return true;
  }

  throw Error(`property ${key} elements are not of type ${type}`);
}

function validateExistenceOf<T>(propertyKey: keyof T, property: unknown): boolean {
  if (typeof property === 'undefined') {
    throw Error(`property ${propertyKey} must be defined`);
  }
  return true;
}

function validateIsLocalizable<T>(propertyKey: keyof T, property: Localizable): boolean {
  validateExistenceOf(propertyKey, property);
  if (typeof property.en === 'undefined' || typeof property.fr === 'undefined') {
    throw Error(`property ${propertyKey} must have text for both english and french`);
  }
  return true;
}

function validateIsDocumentTypes(documentTypes: PdfDocumentType[]): documentTypes is PdfDocumentType[] {
  const allowedDocumentTypes = Object.values(PdfDocumentType);
  const allDocumentTypesAreValid = documentTypes.every((documentType) => allowedDocumentTypes.includes(documentType));
  if (!allDocumentTypesAreValid) {
    throw Error(`invalid PdfDocumentType provided; allowed values are: ${allowedDocumentTypes.join(', ')}`);
  }
  return allDocumentTypesAreValid;
}

function validateIsValidations(validation: Validations): validation is Validations {
  const allowedValidations = Object.values(Validations);
  if (!allowedValidations.includes(validation)) {
    throw Error(
      `invalid value "${validation}" used for Validations. Use one of the valid validation types: ${allowedValidations.join(
        ', '
      )}`
    );
  }
  return true;
}

function validateBaseNode(node: BaseNode): node is BaseNode {
  return validateExistenceOf<BaseNode>('id', node.id);
}

function validateRepeatableOptions(options: RepeatableOptions): options is RepeatableOptions {
  if (isRepeatableOptionsBasedOnCollection(options)) {
    return validateRepeatableOptionsBasedOnCollection(options);
  }
  return validateRepeatableOptionsWithLimits(options);
}

function validateRepeatableOptionsWithLimits(
  options: RepeatableOptionsWithLimits
): options is RepeatableOptionsWithLimits {
  try {
    const { repeatable, minRepetitions, maxRepetitions } = options;
    return (
      validateIsNumber<RepeatableOptionsWithLimits>('minRepetitions', minRepetitions) &&
      validateIsNumber<RepeatableOptionsWithLimits>('maxRepetitions', maxRepetitions) &&
      validateExistenceOf<RepeatableOptionsWithLimits>('repeatable', repeatable)
    );
  } catch (error) {
    throw Error(`invalid repeatableOptionsWithLimits options: ${error.message}`);
  }
}

function validateRepeatableOptionsBasedOnCollection(
  options: RepeatableOptionsBasedOnCollection
): options is RepeatableOptionsBasedOnCollection {
  try {
    const { repeatable, expandToCollectionLength, selectText, selectTitle } = options;
    return (
      validateExistenceOf<RepeatableOptionsBasedOnCollection>('repeatable', repeatable) &&
      validateExistenceOf<RepeatableOptionsBasedOnCollection>('expandToCollectionLength', expandToCollectionLength) &&
      validateIsString<RepeatableOptionsBasedOnCollection>('expandToCollectionLength', expandToCollectionLength) &&
      (!selectText || validateIsArrayOfType(options, 'selectText', 'string')) &&
      (!selectTitle || validateIsArrayOfType(options, 'selectTitle', 'string'))
    );
  } catch (error) {
    throw Error(`invalid repeatableOptionsBasedOnCollection options: ${error.message}`);
  }
}

export function validateQuestionnaire(questionnaire: unknown): questionnaire is Questionnaire {
  if (!Array.isArray(questionnaire)) {
    throw Error('Invalid questionnaire: a questionnaire must be defined as an array');
  }

  return questionnaire.every((sectionGroup) => validateSectionGroup(sectionGroup));
}

export function validateSectionGroup(node: SectionGroup): node is SectionGroup {
  try {
    let isValidRepeatableSectionGroup = true;
    if (isSectionGroupRepeatable(node)) {
      isValidRepeatableSectionGroup =
        validateExistenceOf<RepeatableSectionGroup>('nodeId', node.nodeId) && validateRepeatableOptions(node.options);
    }
    return (
      isValidRepeatableSectionGroup &&
      validateBaseNode(node) &&
      validateIsString<SectionGroup>('index', node.index) &&
      validateIsLocalizable<SectionGroup>('title', node.title) &&
      validateExistenceOf<SectionGroup>('sections', node.sections) &&
      validateSections(node.sections)
    );
  } catch (error) {
    throw Error(`Invalid section group with id ${node.id}: ${error.message}`);
  }
}

function validateSections(nodes: Section[]): nodes is Section[] {
  return nodes.every((section) => validateSection(section));
}

export function validateSection(node: Section): node is Section {
  try {
    return (
      validateBaseNode(node) &&
      validateIsString<Section>('index', node.index) &&
      validateIsLocalizable<Section>('title', node.title) &&
      validateIsDocumentTypes(node.documentTypes) &&
      validateExistenceOf<Section>('subsections', node.subsections) &&
      validateSubsections(node.subsections)
    );
  } catch (error) {
    throw Error(`Invalid section with id ${node.id}: ${error.message}`);
  }
}

function validateSubsections(nodes: Subsection[]): nodes is Subsection[] {
  return nodes.every((subsection) => validateSubsection(subsection));
}

export function validateSubsection(node: Subsection): node is Subsection {
  try {
    return (
      validateBaseNode(node) &&
      validateIsString<Subsection>('index', node.index) &&
      validateIsLocalizable<Subsection>('title', node.title) &&
      validateExistenceOf<Subsection>('questions', node.questions) &&
      validateQuestions(node.questions)
    );
  } catch (error) {
    throw Error(`Invalid subsection with id ${node.id}: ${error.message}`);
  }
}

function validateBaseQuestion(node: BaseQuestion): node is BaseQuestion {
  return (
    validateBaseNode(node) && validateExistenceOf<BaseQuestion>('fields', node.fields) && validateFields(node.fields)
  );
}

function validateQuestions(nodes: Question[]): nodes is Question[] {
  return nodes.every((question) => validateQuestion(question));
}

export function validateQuestion(node: Question): node is Question {
  try {
    let repeatableQuestionIsValid = true;
    if (isQuestionRepeatable(node)) {
      repeatableQuestionIsValid =
        validateExistenceOf<RepeatableQuestion>('removeQuestionButtonText', node.removeQuestionButtonText) &&
        validateExistenceOf<RepeatableQuestion>('addQuestionButtonText', node.addQuestionButtonText) &&
        validateExistenceOf<RepeatableQuestion>('nodeId', node.nodeId) &&
        validateRepeatableOptions(node.options);
    }
    return repeatableQuestionIsValid && validateBaseQuestion(node);
  } catch (error) {
    throw Error(`Invalid question with id ${node.id}: ${error.message}`);
  }
}

function validateFields(nodes: Field[]): nodes is Field[] {
  return nodes.every((field) => validateField(field));
}

function validateBaseField(node: BaseField): node is BaseField {
  if (isReadOnlyField(node)) {
    if (!supportedReadOnlyFieldTypes.includes(node.type)) {
      throw new Error(
        `Invalid read only field type "${node.type}" for field with id "${
          node.id
        }". Valid field types are: ${supportedReadOnlyFieldTypes.join(', ')}`
      );
    }
  } else {
    const nonReadOnlyFieldTypes = _.filter(Object.values(FieldTypes), (type) => !readOnlyFieldTypes.includes(type));

    if (!nonReadOnlyFieldTypes.includes(node.type)) {
      throw new Error(
        `Invalid field type "${node.type}" for field with id "${
          node.id
        }". Make sure to add readOnly: true if using a read only field type. Valid field types are: ${nonReadOnlyFieldTypes.join(
          ', '
        )}`
      );
    }
  }

  return (
    validateBaseNode(node) &&
    validateIsString<BaseField>('type', node.type) &&
    validateIsValidations(node.validation.type)
  );
}

export function validateField(node: Field): node is Field {
  let fieldIsValid: boolean;
  try {
    if (isOptionField(node)) {
      fieldIsValid = validateOptionField(node);
    } else if (isAgreeField(node)) {
      fieldIsValid = validateAgreeField(node);
    } else {
      fieldIsValid = validateBaseField(node);
    }
    return validateBaseNode(node) && validateExistenceOf<Field>('nodeId', node.nodeId) && fieldIsValid;
  } catch (error) {
    throw Error(`Invalid field with id ${node.id}: ${error.message}`);
  }
}

function validateAgreeField(node: AgreeField): node is AgreeField {
  return (
    validateExistenceOf<AgreeField>('label', node.label) &&
    validateExistenceOf<AgreeField>('confirmedLabel', node.confirmedLabel) &&
    validateExistenceOf<AgreeField>('modalHeader', node.modalHeader) &&
    validateExistenceOf<AgreeField>('modalText', node.modalText)
  );
}

function validateOptionField(node: OptionField): node is OptionField {
  return validateBaseField(node) && validateSelectOptions(node.options);
}

function validateSelectOption(node: SelectOption): node is SelectOption {
  return validateBaseNode(node) && validateIsLocalizable<SelectOption>('text', node.text);
}

function validateSelectOptions(nodes: SelectOption[]): nodes is SelectOption[] {
  return nodes.every((node) => validateSelectOption(node));
}
