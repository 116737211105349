import _ from 'lodash';

import { InsuranceScopes } from '@breathelife/types';

import QuestionnaireVisitor from '../QuestionnaireVisitor';
import {
  BaseNode,
  Field,
  isOptionField,
  Question,
  Questionnaire,
  Section,
  SectionGroup,
  Subsection,
} from '../structure';

class FilterNodesByScopesVisitor extends QuestionnaireVisitor {
  private readonly scopes: InsuranceScopes[];

  constructor(scopes: InsuranceScopes[]) {
    super();
    this.scopes = scopes;
  }

  public visitQuestionnaire(questionnaire: Questionnaire): {
    output: void;
    questionnaire: Questionnaire;
  } {
    const clonedQuestionnaire = _.cloneDeep(questionnaire);
    const filteredQuestionnaire = this.filterNodesByScope(clonedQuestionnaire);
    super.visitQuestionnaire(filteredQuestionnaire);

    return { questionnaire: filteredQuestionnaire, output: undefined };
  }

  public visitSectionGroup(sectionGroup: SectionGroup): void {
    sectionGroup.sections = this.filterNodesByScope(sectionGroup.sections);
    super.visitSectionGroup(sectionGroup);
  }

  public visitSection(section: Section): void {
    section.subsections = this.filterNodesByScope(section.subsections);
    super.visitSection(section);
  }

  public visitSubsection(subsection: Subsection): void {
    subsection.questions = this.filterNodesByScope(subsection.questions);
    super.visitSubsection(subsection);
  }

  public visitQuestion(question: Question): void {
    question.fields = this.filterNodesByScope(question.fields);
    super.visitQuestion(question);
  }

  public visitField(field: Field): void {
    if (isOptionField(field)) {
      if (field.options?.length > 0) {
        field.options = this.filterNodesByScope(field.options);
      }
    }
  }

  private filterNodesByScope<T extends BaseNode>(nodes: T[]): T[] {
    return nodes.filter((node) => this.nodeMatchesScopes(node));
  }

  private nodeMatchesScopes(baseNode: BaseNode): boolean {
    if (!baseNode.scopeFilter?.length) {
      // No scopes means this should always be included.
      return true;
    }

    return baseNode.scopeFilter.some((nodeScope) => this.scopes.includes(nodeScope));
  }
}

export function filterNodesByScope(questionnaire: Questionnaire, scopes: InsuranceScopes[]): Questionnaire {
  if (!scopes.length) {
    // If no scopes are provided: do no filtering.
    return questionnaire;
  }

  const visitor = new FilterNodesByScopesVisitor(scopes);
  const { questionnaire: filteredQuestionnaire } = visitor.visitQuestionnaire(questionnaire);

  return filteredQuestionnaire;
}
