import _ from 'lodash';

import { Answers, IAnswerResolver } from '@breathelife/types';

import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import { Field, Questionnaire } from '../structure';

export type SplitSensitiveAnswersOutput = { sensitiveAnswers: Answers; nonSensitiveAnswers: Answers };

class SensitiveAnswersVisitor extends ExpandedRepetitionsVisitor {
  private sensitiveAnswers: Answers = {};
  private nonSensitiveAnswers: Answers = {};

  constructor(answerResolver: IAnswerResolver, answers: Answers) {
    super(answerResolver, answers);
    // All answers are considered sensitive by default unless the current questionnaire says it's not
    this.sensitiveAnswers = _.cloneDeep(answers);
  }

  public getSensitiveAndNonSensitiveAnswers(): SplitSensitiveAnswersOutput {
    return {
      sensitiveAnswers: this.sensitiveAnswers,
      nonSensitiveAnswers: this.nonSensitiveAnswers,
    };
  }

  protected visitField(field: Field): void {
    const { PHI, encrypted, nodeId } = field;
    const shouldBeEncrypted = PHI || encrypted;
    if (shouldBeEncrypted) return;
    // else the answer for this field is not sensitive information and we can put it in the non sensitive answers bucket.

    const value = this.answersResolver.getAnswer(this.answers, nodeId, this.repeatedInstanceIdentifiers());
    if (!(typeof value === 'undefined')) {
      this.answersResolver.setAnswer(
        value,
        this.nonSensitiveAnswers,
        nodeId,
        this.repeatedInstanceIdentifiers(),
        this.answers
      );

      this.answersResolver.unsetAnswer(
        this.sensitiveAnswers,
        nodeId,
        this.repeatedInstanceIdentifiers(),
        this.answers // AnswerPath's can reference answers which may have already been moved from `this.sensitiveAnswers`
      );
    }
  }
}

export function splitSensitiveAnswers(
  questionnaire: Questionnaire,
  answerResolver: IAnswerResolver,
  answers: Answers
): SplitSensitiveAnswersOutput {
  const sensitiveAnswersVisitor = new SensitiveAnswersVisitor(answerResolver, answers);
  sensitiveAnswersVisitor.visitQuestionnaire(questionnaire);
  return sensitiveAnswersVisitor.getSensitiveAndNonSensitiveAnswers();
}
