import _ from 'lodash';

import BaseBuilder from './BaseBuilder';

export default abstract class NodeBuilder<
  BaseBuilderNodeType,
  NodeType extends BaseBuilderNodeType = BaseBuilderNodeType
> extends BaseBuilder<BaseBuilderNodeType> {
  protected node: Partial<NodeType>;

  protected constructor(nodeSeed: Partial<NodeType>) {
    super();
    this.node = nodeSeed;
  }

  public abstract validate(node: NodeType): node is NodeType;

  public build(): NodeType {
    try {
      this.validate(this.node as NodeType);
      return _.cloneDeep(this.node) as NodeType;
    } catch (error) {
      throw Error(`Error when building: ${error.message}`);
    }
  }
}
