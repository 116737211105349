export const ENGINE_VERSION = 1;

export * from './external';

export * from './structure';
export {
  getRepeatedQuestionIndex,
  getContainingSectionGroup,
  getContainingSection,
  findSection,
  findSubsection,
  findSubsectionInQuestionnaire,
  findQuestion,
  findField,
  getAllSections,
  getAllSubsections,
  getAllQuestions,
  getAllFields,
  getFieldTitlesByNodeId,
  getSubsectionInsuranceModules,
} from './questionnaire';

export {
  OnAnswerChange,
  OnBulkAnswerClear,
  RepeatedIndices,
  getAllAnswersWithinSubsectionScope,
  getMergedStepAnswers,
  areAnyFieldsAnsweredWithinSubsectionScope,
} from './answers';

export { evaluateConditions, evaluateQuery } from './conditions';
export {
  AnswerPath,
  NodeIdAnswersResolver,
  NodeIdToAnswerPathMap,
  AnswersTransformer,
  makeNodeIdToAnswerPathMap,
  AnswerPathTreeNode,
  verifyNodeIdToAnswerPathMap,
  verifyAllNodeIdsAreUsed,
} from './answersResolver';

export {
  Answers,
  RepeatedAnswersBySurrogateId,
  RepeatedAnswers,
  CollectionInstanceIdentifier,
  CollectionInstanceIdentifiers,
} from '@breathelife/types';

export { ValidityRule } from './nodeEvaluation';

export { copyAnswers } from './copyAnswers/copyAnswers';

export {
  toCustomField,
  toCustomFieldGroup,
  toCustomSubsection,
  toCustomSection,
  switchToCustomPartName,
} from './questionnaireHelpers/toCustomBlueprint';

export { yesNoOptions, emptyTitle } from './questionnaireHelpers/field';
export {
  valueAboveMinimum,
  valueBelowMaximum,
  valueDoesNotExceedCharacterLimit,
  valueHasAtLeastGivenCharacters,
  agreeFieldIsAccepted,
  noneOfTheAboveValidity,
} from './questionnaireHelpers/validIfs';

export { getNodeIdsFromBlueprints } from './questionnaireHelpers/getNodeIdsFromBlueprints';

export { defaultOutcomeSchema, OutcomeDeterminator, OutcomeSchema } from './decisionEngine/OutcomeDeterminator';

export {
  phoneNumberRegex,
  emailRegex,
  phoneAreaCodeList,
  Validations,
  ValidationError,
  isValidPhoneNumberAreaCode,
  fieldValidation,
  stringWithEmptyAllowedIfOptional,
  getFieldValidations,
} from './validations';

export {
  RenderingQuestionnaireGenerator,
  RenderingQuestionnaire,
  RenderingSection,
  RenderingSubsection,
  RenderingQuestion,
  RenderingRepeatedQuestion,
  RenderingField,
  RenderingSectionGroup,
  RenderingFieldOption,
  RenderingTextField,
  RenderingNumberField,
  RenderingAutocompleteField,
  RenderingInformationField,
  RenderingOptionField,
  RenderingAgreeField,
  RenderingSignatureField,
  TransitionQuestionnaire,
  ActiveSectionId,
  isRenderingTextField,
  isRenderingRadioField,
  isRenderingAgreeField,
  isRenderingButtonField,
  isRenderingOptionField,
  isRenderingPlaceholderField,
  isRenderingCheckboxGroupField,
  isRenderingRepeatedSectionGroup,
  isRenderingRepeatedQuestion,
  isRenderingInformationField,
  isRenderingQuestionnaireComplete,
  isRenderingSignatureField,
  findFieldValue,
  getSectionById,
  getPreviousSectionFromAllGroups,
  getNextSectionFromAllGroups,
  REPLACE_WITH_OPTION_ID,
  findField as findRenderingField,
  QuoterRenderingSubsection,
  PriceUpdateWarningRenderingSubsection,
  SubmissionRenderingSubsection,
  RenderingInfoSupplement,
} from './renderingTransforms';

export { formatHeightToFeetAndCm, formatWeightToLbsAndKg } from './unitFormatter';
export { TextGetter } from './locale';
export { filterQuestionnaireSectionsWithDocumentType } from './documentType';

export { AnswersEncryption, EncryptionConfig } from './crypto';
export { mergeAnswers } from './utils/mergeAnswers';
export { moveAnswersToNewPaths } from './utils/moveAnswersToNewPaths';
export {
  serializeNodeIdToAnswerPathMap,
  deserializeNodeIdToAnswerPathMap,
  SerializedNodeIdToAnswerPathMap,
} from './utils/apiUtils';
export { generateAnswersDataDescriptor } from './answerDataDescriptor/generate';

export { Builders, SeedProviders } from './builders';

export { TestUtils } from './utils/testUtils';

export {
  overrideQuestionnaireProperties,
  overrideQuestion,
  PartialQuestionnaire,
  PartialSection,
  PartialSectionGroup,
  PartialQuestion,
  PartialField,
  PartialSelectOption,
  PartialSubsection,
  PartialCondition,
  PartialValidityRule,
} from './questionnaireHelpers/overrideQuestionnaireProperties';

export { validateQuestionnaire } from './structureValidations';
