import _ from 'lodash';

import { Answers } from '@breathelife/types';

import { areAnswersForRepeatedQuestions } from '../answers';

/** Recursively merge properties of two objects while treating arrays like single values to copy
 rather than merging them like deep objects (as with lodash's merge())
 Ex:
  _.merge({a: [1, 2], b: [5]}, {a: [1]});   // Result: {a: [1, 2], b: [5]}
  mergeCopyArrays({a: [1, 2], b: [5]}, {a: [1]});   // Result: {a: [1], b:[5]}
 */
function mergeCopyArrays(destination: { [key: string]: any }, ...sources: { [key: string]: any }[]): void {
  sources.forEach((source: {}) => {
    _.mergeWith(destination, source, (value: any, srcValue: any) => {
      // If it's a repeatable node, we merge them like deep objects (original behaviour of _.merge)
      if (areAnswersForRepeatedQuestions(value)) return undefined;
      // If it's an array but not a repeatable node, it's because it's an array of values, like answers to a checkboxGroup field
      // In this case, we just copy the values
      if (Array.isArray(value)) return srcValue;

      // In all other cases, we revert back to the default _.merge behaviour
      return undefined;
    });
  });
}

export function mergeAnswers(
  previousAnswers: Answers | null,
  newAnswers: Answers,
  options?: { skipCloneDeep?: boolean }
): Answers {
  const originalAnswers = previousAnswers ?? {};
  const answers = options?.skipCloneDeep ? originalAnswers : _.cloneDeep(originalAnswers);
  mergeCopyArrays(answers, newAnswers);
  return answers;
}
