import { AnswerPath } from '../answersResolver/AnswerPath';
import { NodeIdToAnswerPathMap } from '../answersResolver/makeNodeIdToAnswerPathMap';

export type SerializedNodeIdToAnswerPathMap = [string, AnswerPath][];

export function serializeNodeIdToAnswerPathMap(
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap
): SerializedNodeIdToAnswerPathMap {
  return Array.from(nodeIdToAnswerPathMap.entries());
}

export function deserializeNodeIdToAnswerPathMap(
  nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap
): NodeIdToAnswerPathMap {
  return new Map(nodeIdToAnswerPath);
}
