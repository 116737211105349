import _ from 'lodash';

import {
  IAnswerResolver,
  CollectionInstanceIdentifier,
  CollectionInstanceIdentifiers,
  RepeatedAnswersBySurrogateId,
  Answers,
} from '@breathelife/types';

import { AnswerPath, isReferencePath } from './AnswerPath';

export type NodeInstance = {
  id: string;
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers;
};

export type RepeatedAnswers = {
  repeatedIndex: number;
  answersByNodeId: {
    [nodeId: string]: any;
  };
};

const isReachingTheHaltPoint = (
  answerPath: AnswerPath,
  collectionNodeId: string,
  nodeIdToAnswerPath: Map<string, AnswerPath>
): boolean => {
  let currentParentPath = answerPath.parent;

  const collectionPath = nodeIdToAnswerPath.get(collectionNodeId);
  while (currentParentPath) {
    if (_.isEqual(collectionPath, currentParentPath)) {
      return true;
    }
    currentParentPath = currentParentPath.parent;
  }
  return false;
};

export class NodeIdAnswersResolver implements IAnswerResolver {
  // `nodeIdToAnswerPath` contents should never be modified outside of the constructor.
  private readonly nodeIdToAnswerPath: Map<string, AnswerPath>;

  // This could be removed if we are sure we do not want to prefix reference paths.
  private static readonly NODE_REFERENCE_PATH_PREFIX = '';

  private readonly insuredPeopleHackId: string;

  constructor(nodeIdToAnswerPath: Map<string, AnswerPath>, insuredPeopleHackId = 'insured-people') {
    this.nodeIdToAnswerPath = new Map<string, AnswerPath>(nodeIdToAnswerPath);
    this.insuredPeopleHackId = insuredPeopleHackId;
  }

  public static buildReferencePathKey(value: string | number): string {
    return `${NodeIdAnswersResolver.NODE_REFERENCE_PATH_PREFIX}${value}`;
  }

  public knowsId(id: string): boolean {
    return this.nodeIdToAnswerPath.has(id);
  }

  public getAnswer(
    answers: Answers, // TODO: Answers is an implementation detail from the type should not be known to outsider callers.
    id: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers,
    skipLeafIdentifier?: boolean
  ): any | undefined {
    const answerPath = this.getAnswerPath(id);

    const { qualifiedPath } = this.buildFullyQualifiedPath({
      answers: answersForPath ?? answers,
      answerPath,
      collectionInstanceIdentifiers,
      skipLeafIdentifier: !!skipLeafIdentifier,
    });

    return qualifiedPath ? _.get(answers, qualifiedPath) : undefined;
  }

  public getCollection(
    answers: Answers,
    nodeId: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers
  ): any[] | undefined {
    const answerPath = this.getAnswerPath(nodeId);
    if (!answerPath.isCollection) {
      throw Error(`${nodeId} does not reference a collection`);
    }

    return this.getAnswer(answers, nodeId, collectionInstanceIdentifiers, undefined, true);
  }

  public isCollection(nodeId: string): boolean {
    const answerPath = this.getAnswerPath(nodeId);
    return answerPath.isCollection;
  }

  /** Gets answers from within a collection. Returns those answers in an object keyed by the containing surrogateId */
  public getRepeatedAnswers(
    answers: Answers,
    collectionNodeId: string,
    nodeIds: string[],
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers
  ): RepeatedAnswersBySurrogateId | undefined {
    const collectionAnswerPath = this.getAnswerPath(collectionNodeId);
    if (!collectionAnswerPath.isCollection) throw Error(`nodeId '${collectionNodeId}' is not a collection.`);

    const nodeIdsWithAnswerPaths: { nodeId: string; answerPath: AnswerPath }[] = nodeIds.map((nodeId) => {
      const answerPath = this.getAnswerPath(nodeId);
      return { answerPath, nodeId };
    });

    const { collectionAnswers } = this.getCollectionAnswers(
      answers,
      collectionAnswerPath,
      collectionInstanceIdentifiers
    );
    if (typeof collectionAnswers === 'undefined') {
      return undefined;
    }

    const repeatedAnswersBySurrogateId: RepeatedAnswersBySurrogateId = {};

    nodeIdsWithAnswerPaths.forEach(({ nodeId, answerPath }: { nodeId: string; answerPath: AnswerPath }) => {
      const shouldUseGlobalAnswers = isReachingTheHaltPoint(answerPath, collectionNodeId, this.nodeIdToAnswerPath);
      Object.values(collectionAnswers).forEach((repeatedAnswers, index) => {
        let surrogateId = _.get(repeatedAnswers, 'surrogateId');
        if (!surrogateId) {
          surrogateId = index;
        }

        const { qualifiedPath: childFullyQualifiedAnswerPath } = this.buildFullyQualifiedPath({
          answers,
          answerPath: answerPath,
          collectionInstanceIdentifiers: shouldUseGlobalAnswers
            ? collectionInstanceIdentifiers
            : { ...collectionInstanceIdentifiers, [collectionNodeId]: index },
          haltAtAnswerPath: collectionAnswerPath,
        });

        const answer =
          typeof childFullyQualifiedAnswerPath !== 'undefined'
            ? _.get(shouldUseGlobalAnswers ? repeatedAnswers : answers, childFullyQualifiedAnswerPath)
            : undefined;

        if (!repeatedAnswersBySurrogateId[surrogateId]) {
          repeatedAnswersBySurrogateId[surrogateId] = { answersByNodeId: {}, repeatedIndex: index };
        }

        repeatedAnswersBySurrogateId[surrogateId].answersByNodeId[nodeId] = answer;
      });
    });

    return repeatedAnswersBySurrogateId;
  }

  /** Gets the number of repetitions at a collection node id */
  public getRepetitionCount(
    answers: Answers,
    collectionNodeId: string,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers
  ): number | undefined {
    const collectionAnswerPath = this.getAnswerPath(collectionNodeId);
    if (!collectionAnswerPath.isCollection) throw Error(`nodeId '${collectionNodeId}' is not a collection.`);

    const { collectionAnswers } = this.getCollectionAnswers(
      answers,
      collectionAnswerPath,
      collectionInstanceIdentifiers
    );

    return collectionAnswers?.length;
  }

  public setAnswer(
    value: unknown,
    answers: Answers,
    nodeId: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers
  ): void {
    const answerPath = this.getAnswerPath(nodeId);

    if (answerPath.isCollection) {
      this.setRepeatedAnswer(value, answers, answerPath, collectionInstanceIdentifiers);
    } else {
      const { qualifiedPath } = this.buildFullyQualifiedPath({
        answers: answersForPath ?? answers,
        answerPath,
        collectionInstanceIdentifiers,
      });

      if (qualifiedPath) {
        if (typeof value === 'undefined') {
          _.unset(answers, qualifiedPath);
        } else {
          _.set(answers, qualifiedPath, value);
        }
      }
    }
  }

  public unsetAnswer(
    answers: Answers,
    nodeId: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers
  ): boolean {
    const answerPath = this.getAnswerPath(nodeId);

    const { qualifiedPath } = this.buildFullyQualifiedPath({
      answers: answersForPath ?? answers,
      answerPath,
      collectionInstanceIdentifiers,
      skipLeafIdentifier: false,
    });

    if (qualifiedPath) {
      const isCurrentValueSet = typeof _.get(answers, qualifiedPath) !== 'undefined';
      _.unset(answers, qualifiedPath);
      return isCurrentValueSet;
    }

    return false;
  }

  public unsetAnswers(answers: Answers, nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, collectionInstanceIdentifiers } = nodeInstance;
      const wasRemoved = this.unsetAnswer(answers, id, collectionInstanceIdentifiers);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  /** Remove an optionId from a given answer, for both single and multi-select. Returns `true` if answers were modified. */
  public unsetAnswerSelectOptionId(
    answers: Answers,
    nodeId: string,
    optionId: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers
  ): boolean {
    const answerPath = this.getAnswerPath(nodeId);
    const { qualifiedPath } = this.buildFullyQualifiedPath({ answers, answerPath, collectionInstanceIdentifiers });

    if (qualifiedPath) {
      const currentOptionOrOptions = _.get(answers, qualifiedPath);

      if (Array.isArray(currentOptionOrOptions)) {
        // Multi-select or checkbox.
        const wasOptionSet = currentOptionOrOptions.includes(optionId);

        if (wasOptionSet) {
          const newOptions = currentOptionOrOptions.filter((id) => optionId !== id);
          this.setAnswer(newOptions, answers, nodeId, collectionInstanceIdentifiers);
        }

        return wasOptionSet;
      } else if (currentOptionOrOptions === optionId) {
        // Single select or radio.
        return this.unsetAnswer(answers, nodeId, collectionInstanceIdentifiers);
      }
    }

    return false;
  }

  /** Duplicate `identifiers` with `collectionIdentifier` inserted at `collectionNodeId`'s position */
  public withCollectionIdentifier(
    identifiers: CollectionInstanceIdentifiers,
    collectionIdentifier: CollectionInstanceIdentifier,
    collectionNodeId: string
  ): CollectionInstanceIdentifiers {
    const collectionAnswerPath = this.getAnswerPath(collectionNodeId);

    if (!collectionAnswerPath.isCollection) {
      throw Error(`${collectionNodeId} does not reference a collection`);
    }

    const duplicatedIdentifiers = { ...identifiers };
    duplicatedIdentifiers[collectionNodeId] = collectionIdentifier;

    return duplicatedIdentifiers;
  }

  private getAnswerPath(nodeId: string): AnswerPath {
    const answerPath = this.nodeIdToAnswerPath.get(nodeId);
    if (!answerPath) {
      throw Error(`nodeId '${nodeId}' not found`);
    }

    return answerPath;
  }

  private buildFullyQualifiedPath({
    answerPath,
    answers,
    collectionInstanceIdentifiers,
    haltAtAnswerPath,
    skipLeafIdentifier,
  }: {
    answerPath: AnswerPath;
    answers: Answers;
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers;
    haltAtAnswerPath?: AnswerPath;
    skipLeafIdentifier?: boolean;
  }): {
    qualifiedPath: string | undefined;
  } {
    let qualifiedPath: string = '';

    if (isReferencePath(answerPath.path)) {
      // This is a special case where the path is set by the value at another nodeId.
      // ~99% of the time the `else` block will be executed here.

      const valueAtNodeId = this.getAnswer(answers, answerPath.path.fromValueAt, collectionInstanceIdentifiers);
      if (typeof valueAtNodeId === 'undefined') {
        return { qualifiedPath: undefined };
      }

      const valueType = typeof valueAtNodeId;
      if (valueType === 'object' || valueType === 'function') {
        throw Error('`answerPath.path.fromValueAt` must reference a scalar value');
      }

      qualifiedPath = NodeIdAnswersResolver.buildReferencePathKey(valueAtNodeId);
    } else if (answerPath.path) {
      qualifiedPath = answerPath.path;
    }

    if (answerPath.parent) {
      // The parent node will add to the qualified path.

      const stopTraversal = _.isEqual(answerPath.parent, haltAtAnswerPath);
      if (stopTraversal) {
        // This is a special case that can be ignored most of the time.
        return { qualifiedPath };
      }

      // Recursively visit the parent node (any consumed `collectionInstanceIdentifiers` will be removed in `updatedCollectionInstanceIdentifiers`).
      const { qualifiedPath: parentQualifiedPath } = this.buildFullyQualifiedPath({
        answers,
        answerPath: answerPath.parent,
        collectionInstanceIdentifiers,
        haltAtAnswerPath,
        skipLeafIdentifier: false,
      });

      if (typeof parentQualifiedPath === 'undefined') {
        return { qualifiedPath: undefined };
      }

      qualifiedPath = `${parentQualifiedPath}.${qualifiedPath}`;
    }

    if (answerPath.isCollection && answerPath.nodeId && !skipLeafIdentifier) {
      // For collections we often need to know _which_ item in the collection we are building a path for.
      //
      // The data in `currentCollectionInstanceIdentifiers` comes from sources external to the engine.
      //  For instance maybe we are loading answers into a React component for a field in a repeated question...
      //  in that case the collection identifier would be set from the index in the loop containing that field.

      if (typeof collectionInstanceIdentifiers?.[answerPath.nodeId] !== 'undefined') {
        const index = collectionInstanceIdentifiers[answerPath.nodeId];
        qualifiedPath = `${qualifiedPath}.${index}`;
      } else if (answerPath.nodeId === this.insuredPeopleHackId) {
        /*
         * MULTI-INSURED HACK
         *
         * This detects situations where a `multi-insured` collection index is not passed into the system.
         *
         * This is meant to prevent our systems from breaking while we add multi-insured capabilities to the system.
         *  (It will allow systems that do not provide indices for multi-insured to still work as though there was a single insured.)
         */
        const index = 0;
        qualifiedPath = `${qualifiedPath}.${index}`;
        // END MULTI-INSURED HACK
      } else {
        // Trying to find an item when no index is provided.
        return { qualifiedPath: undefined };
      }
    }
    return { qualifiedPath };
  }

  public removeUndefinedAnswersFromCollection(
    nodeId: string,
    answers: Answers,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers
  ): boolean {
    const answerPath = this.nodeIdToAnswerPath.get(nodeId);

    if (!answerPath || !answerPath?.isCollection) {
      return false;
    }

    const collectionPath = this.buildFullyQualifiedPath({
      answers: answers,
      answerPath,
      collectionInstanceIdentifiers,
      skipLeafIdentifier: true,
    });

    const collectionQualifiedPath = collectionPath.qualifiedPath;

    if (collectionQualifiedPath) {
      const collection = _.get(answers, collectionQualifiedPath);
      if (Array.isArray(collection)) {
        if (collection.length === 0 || collection.every((e) => e === undefined)) {
          // Delete the keys if no items are left in it.
          _.unset(answers, collectionQualifiedPath);
        } else {
          // Remove all the undefined betweens items. (_.unset) creates undefined (which is good for removing without losing index validity).
          _.set(
            answers,
            collectionQualifiedPath,
            collection.filter((e: any) => !!e)
          );
        }
        return true;
      }
    }

    return false;
  }

  private getCollectionAnswers(
    answers: Answers,
    collectionAnswerPath: AnswerPath,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers
  ): {
    collectionAnswers: Answers[] | undefined;
  } {
    const { qualifiedPath: collectionFullyQualifiedAnswerPath } = this.buildFullyQualifiedPath({
      answers,
      answerPath: collectionAnswerPath,
      collectionInstanceIdentifiers,
      skipLeafIdentifier: true, // Do not add an index to the collection's qualified path, we want an array.
    });

    const collectionAnswers: Answers[] = collectionFullyQualifiedAnswerPath
      ? _.get(answers, collectionFullyQualifiedAnswerPath)
      : undefined;

    if (typeof collectionAnswers === 'undefined') {
      return { collectionAnswers: undefined };
    }

    return { collectionAnswers };
  }

  private setRepeatedAnswer(
    value: any,
    answers: Answers,
    answerPath: AnswerPath,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers
  ): void {
    const isRepeatedInstanceRemoval = typeof value === 'undefined';
    const isSettingEntireCollection = Array.isArray(value);

    const { qualifiedPath } = this.buildFullyQualifiedPath({
      answers: answersForPath ?? answers,
      answerPath,
      collectionInstanceIdentifiers,
      skipLeafIdentifier: isSettingEntireCollection || isRepeatedInstanceRemoval,
    });

    if (!qualifiedPath) return;

    if (isRepeatedInstanceRemoval) {
      if (!collectionInstanceIdentifiers) {
        throw Error('Collection instance identifier(s) must be defined when removing repeated instances');
      }

      const repeatedAnswers = _.get(answers, qualifiedPath);
      const repeatedInstanceIdentifier = answerPath.nodeId
        ? collectionInstanceIdentifiers?.[answerPath.nodeId]
        : undefined;

      if (repeatedInstanceIdentifier === undefined) {
        throw Error(
          `repeatedInstanceIdentifier could not be found in setRepeatedAnswer. (nodeId: ${answerPath.nodeId})`
        );
      }

      if (Array.isArray(repeatedAnswers)) {
        repeatedAnswers?.splice(repeatedInstanceIdentifier as number, 1);
        _.set(answers, qualifiedPath, repeatedAnswers);
      } else {
        throw Error('Cannot remove a repeated instance from a non-array collection');
      }
    } else {
      _.set(answers, qualifiedPath, value);
    }
  }
}
